import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { addProduct, removeProduct } from 'store/cart';
import Title from 'components/UI/Title';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import NavActions from 'components/UI/NavActions';
import ProductCard from 'components/UISchedule/ProductCard';
import ProofBookUpsellModal from 'components/UISchedule/ProofBookUpsellModal';

import CartService from 'services/CartService';
import ProductService from 'services/ProductService';

import ProductConstants from 'utils/ProductConstants';
import ProductHelper from 'helpers/ProductHelper';

import styles from './SelectProduct.module.css';
import ExtraPoseYearbookPlusModal from 'components/UISchedule/ExtraPoseYearbookPlusModal/ExtraPoseYearbookPlusModal';

export default function SelectProduct({ type, title }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { accesscode } = useParams();
    const [products, setProducts] = useState([]);
    const [areProductsLoading, setProductsLoading] = useState(true);
    const [isYrbkPlusModalOpen, setYrbkPlusModalOpen] = useState(false);
    const [isPrfBookUpsellActive, setPrfBookUpsellActive] = useState(false);
    const { products: cartProducts, appointmentInfo } = useSelector(state => state.cart);
    const mainProduct = cartProducts.filter(product => product.packagePlanNumber.toUpperCase() === ProductConstants.getPackagePlanNumber("main"))[0];
    const proofProduct = cartProducts.filter(product => product.packagePlanNumber.toUpperCase() === ProductConstants.getPackagePlanNumber("proof"))[0];

    useEffect(() => {
        const fetchProducts = async () => {
            setProductsLoading(true);
            const res = await ProductService.getProducts(accesscode, type);
            setProductsLoading(false);
            if (res.data.status === 200) {
                const loadedProducts = res.data.products;
                setProducts([...loadedProducts]);
            }
        };
        fetchProducts();

        return () => { setYrbkPlusModalOpen(false); setPrfBookUpsellActive(false); };
    }, [accesscode, type]);

    const goToSelectProofPage = () => {
        history.push(`/schedule/${accesscode}/proofing`)
    };

    const goToCart = () => {
        history.push(`/cart`)
    }

    const handleAddToCart = async (product, setLoading, type, suppressUpsell = false) => {
        if (ProductHelper.isOnlinePrfProduct(product) && !suppressUpsell) {
            setPrfBookUpsellActive(true);
            return;
        }

        setLoading(true);
        try {
            if (type === "main" && mainProduct) await CartService.removeProductFromCart(mainProduct.id);
            if (type === "proof" && proofProduct) await CartService.removeProductFromCart(proofProduct.id);

            const { data } = await CartService.addProductToCart(accesscode, product.id);
            if (data.status !== 200) {
                return;
            }

            dispatch(addProduct({ product: product, type }));
            if (ProductHelper.isYearbookPlusProduct(product)) {
                setYrbkPlusModalOpen(true);
                setLoading(false);
                return;
            }
            if (type === "main" && !proofProduct) {
                history.push(`/schedule/${accesscode}/proofing`);
                return;
            }

            if (!appointmentInfo || Object.keys(appointmentInfo).length === 0) {
                history.push(`/cart/find-time`);
                return;
            }

            history.push(`/cart`);
        } catch {
            setLoading(false);
        }
    };

    const handleRemoveFromCart = async (product, setLoading) => {
        setLoading(true);
        try {
            const { data } = await CartService.removeProductFromCart(product.id);
            if (data.status !== 200) {
                return;
            }
            setLoading(false);
            dispatch(removeProduct({ type }));
        } catch {
            setLoading(false);
        }
    };

    const handlePrfBookUpsellSuccess = async (setLoading) => {
        setPrfBookUpsellActive(false);
        const bookPrfProd = ProductHelper.findBookPrfProduct(products);
        await handleAddToCart(bookPrfProd, setLoading, type);

    };

    const handlePrfBookUpsellDeny = async (setLoading) => {
        setPrfBookUpsellActive(false);
        const onlinePrfProd = ProductHelper.findOnlinePrfProduct(products);
        await handleAddToCart(onlinePrfProd, setLoading, type, true);
    };

    const handleYearbookPlusInfoModalClose = async (isOpen) => {
        setYrbkPlusModalOpen(isOpen);
        goToSelectProofPage();
    };

    return (
        <>
            <div className={styles.SelectMainProduct}>
                <NavActions onGoBack={() => { history.replace(`/schedule/${accesscode}/${type === "proof" ? "sessions" : ""}`) }}>
                    {
                        mainProduct && type !== "proof" &&
                        <Button onClick={goToSelectProofPage} clear>Continue</Button>
                    }
                    {
                        mainProduct && proofProduct &&
                        <Button onClick={goToCart} clear>Review order</Button>
                    }
                </NavActions>
                <Title>{title}</Title>
                {
                    areProductsLoading ?
                        <Loading />
                        :
                        <div className={styles.Products}>
                            {products.length ?
                                products.map((product) => {
                                    return <ProductCard
                                        product={product}
                                        key={product.id}
                                        accessCode={accesscode}
                                        isSelected={cartProducts.filter(item => item.id === product.id).length !== 0}
                                        type={type}
                                        onAddToCart={handleAddToCart}
                                        onRemoveFromCart={handleRemoveFromCart} />
                                })
                                :
                                "No products found."
                            }
                        </div>
                }

            </div>
            <ExtraPoseYearbookPlusModal isOpen={isYrbkPlusModalOpen} setOpen={handleYearbookPlusInfoModalClose} />
            <ProofBookUpsellModal
                isOpen={isPrfBookUpsellActive}
                onSuccess={handlePrfBookUpsellSuccess}
                onDeny={handlePrfBookUpsellDeny} />
        </>
    )
};