import api from "http/index";

class SeniorsScheduleService {
    async loadState() {
        return api.get("SeniorsSchedule/State");
    }

    async loadSchedule(accessCode) {
        return api.get(`SeniorsSchedule/${this.#normilizeAccessCode(accessCode)}/Load`);
    }

    async searchAccessCodes(lastName, studentNumber) {
        return api.get(`SeniorsSchedule/AccessCode/${lastName ? lastName : ''}/${studentNumber ? studentNumber : ''}`);
    };

    async getAccessCodeInfo(code) {
        return api.get(`SeniorsSchedule/AccessCode/${this.#normilizeAccessCode(code)}`);
    }

    async updateAccessCodeInfo(code, accessCodeInfo) {
        const { email, phone, address1, address2, city, state, zipCode, isConsent, consentId } = accessCodeInfo;

        const postData = new FormData();

        postData.append("email", email || '');
        postData.append("phone", phone || '');
        postData.append("address1", address1 || '');
        postData.append("address2", address2 || '');
        postData.append("city", city || '');
        postData.append("state", state || '');
        postData.append("zipCode", zipCode || '');
        postData.append("isConsent", isConsent);
        postData.append("consentId", consentId || '');

        return api.post(`SeniorsSchedule/AccessCode/${this.#normilizeAccessCode(code)}`, postData);
    }

    async getStudios(accessCode, productCode) {
        const urlParams = new URLSearchParams();
        urlParams.append("productCode", productCode);
        return api.get(`SeniorsSchedule/Studios/${this.#normilizeAccessCode(accessCode)}?` + urlParams);
    }

    async getAmountOfStudioTimeSlots(accessCode, contractNumber, productCode) {
        return api.get(`TimeSlots/${this.#normilizeAccessCode(accessCode)}/Studio/${contractNumber}/Product/${productCode}`);
    }

    async getStudioTimeSlots(accessCode, contractNumber, productCode, date) {
        const urlParams = new URLSearchParams();

        urlParams.append("productCode", productCode);

        return api.get(`SeniorsSchedule/${this.#normilizeAccessCode(accessCode)}/Studio/${contractNumber}/TimeSlots/${date.replaceAll("/", "-")}/?` + urlParams);
    }

    async holdTimeSlot(accessCode, contractNumber, productCode, date, time) {
        const urlParams = new URLSearchParams();

        const [time12, dayPart] = time.split(" ");
        const [hours12, minutes] = time12.split(":");
        const hours12Num = parseInt(hours12);
        let hours24 = hours12;
        if (dayPart === "PM" && hours12Num < 12) hours24 = hours12Num + 12;
        if (dayPart === "AM" && hours12Num === 12) hours24 = hours12Num - 12;
        const time24 = `${hours24 < 10 ? "0" + hours24.toString() : hours24}:${minutes.length === 1 ? "0" + minutes : minutes}`;

        urlParams.append("date", date.replaceAll("/", "-"));
        urlParams.append("time", time24);
        urlParams.append("productCode", productCode);

        return api.post(`SeniorsSchedule/Studio/${contractNumber}/Schedule/${this.#normilizeAccessCode(accessCode)}/Hold?` + urlParams);
    }

    async rescheduleTime(accessCode, contractNumber, productCode, date, time) {
        const urlParams = new URLSearchParams();

        const [time12, dayPart] = time.split(" ");
        const [hours12, minutes] = time12.split(":");
        const hours12Num = parseInt(hours12);
        let hours24 = hours12;
        if (dayPart === "PM" && hours12Num < 12) hours24 = hours12Num + 12;
        if (dayPart === "AM" && hours12Num === 12) hours24 = hours12Num - 12;
        const time24 = `${hours24 < 10 ? "0" + hours24.toString() : hours24}:${minutes.length === 1 ? "0" + minutes : minutes}`;

        urlParams.append("date", date.replaceAll("/", "-"));
        urlParams.append("time", time24);
        urlParams.append("mainProductCode", productCode);

        return api.patch(`SeniorsSchedule/Studio/${contractNumber}/Schedule/${this.#normilizeAccessCode(accessCode)}?` + urlParams);
    }

    async cancel(accessCode) {
        return api.get(`SeniorsSchedule/${this.#normilizeAccessCode(accessCode)}/Cancel`);
    }

    async cancelWithoutRefund(accessCode) {
        return api.get(`SeniorsSchedule/${this.#normilizeAccessCode(accessCode)}/Cancel?refund=false`);
    }

    #normilizeAccessCode(code) {
        return code.trim().replaceAll("-", "");
    }
}

export default new SeniorsScheduleService();