import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addNotification, removeNotification } from 'store/notification';
import { clearCartState } from 'store/cart';
import AppointmentHoldTimer from 'components/UISchedule/AppointmentHoldTimer';
import Title from 'components/UI/Title';
import Input from 'components/UI/Input';
import AddressForm from 'components/UI/AddressForm';
import NavActions from 'components/UI/NavActions';
import CartService from 'services/CartService';

import styles from './PayCart.module.css';
import Loading from 'components/UI/Loading/Loading';
import ProductHelper from 'helpers/ProductHelper';

export default function PayCart() {
    const minInMs = 60000;
    const dispatch = useDispatch();
    const history = useHistory();
    const { accessCode, products, appointmentInfo, total } = useSelector(state => state.cart);
    const mainProduct = ProductHelper.findProduct(products, "main");
    const proofProduct = ProductHelper.findProduct(products, "proof");
    const initialPayInfo = { cardInfo: { cardNumber: '', cardCode: '', expDate: '' }, firstName: '', lastName: '', email: '', phone: '', address1: '', address2: '', city: '', zipCode: '', state: '' };
    const [payInfo, setPayInfo] = useState(initialPayInfo);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [validTotal, setTotal] = useState(total);
    const [componentLoading, setComponentLoading] = useState(true);

    const notify = useCallback((notification) => {
        dispatch(addNotification(notification));
        setTimeout(() => { dispatch(removeNotification(notification)) }, 4000);
    }, [dispatch]);

    useEffect(() => {
        if (!appointmentInfo
            || Object.keys(appointmentInfo).length === 0
            || !appointmentInfo.date || !appointmentInfo.slot || parseInt(appointmentInfo.holdAt) + (15 * minInMs) < Date.now()
            || !appointmentInfo.selectedStudio || !appointmentInfo.selectedStudio.contractNumber) {
            history.goBack();
            return;
        }

        const fetchCheckoutInfo = async () => {
            setComponentLoading(true);
            try {
                const { data } = await CartService.checkout(accessCode, mainProduct.id, proofProduct.id);
                setComponentLoading(false);
                if (data.status !== 200) {
                    if (data.hasScheduled) {
                        const notification = { type: "info", content: data.message };
                        notify(notification);
                        history.replace(`/schedule/${accessCode}`);
                        dispatch(clearCartState());
                        return;
                    }
                }
                if (data.proceedToPay) {
                    setTotal(Math.round(data.total * 100) / 100 || total);
                } else {
                    const notification = { type: "info", content: "Appointment was succesefully scheduled. Review appointment information." };
                    notify(notification);
                    history.replace("/schedule/" + accessCode);
                    dispatch(clearCartState());
                    return;
                }
            } catch {
                setComponentLoading(false);
                dispatch(clearCartState());
                history.goBack();
                return;
            }
        };

        if (accessCode && mainProduct && proofProduct) {
            fetchCheckoutInfo();
            return;
        }

        if (!accessCode) {
            history.push("/schedule");
            return;
        }

        if (!mainProduct || !proofProduct) {
            const notification = {
                type: "info",
                content: "Main product and proof product are required for checkout."
            };
            notify(notification);
            history.goBack();
            return;
        }
    }, [dispatch, notify, history, accessCode, mainProduct, proofProduct, total, appointmentInfo]);


    const onPay = async (ev) => {
        ev.preventDefault();
        if (isLoading) return;
        try {
            setLoading(true);
            const { data } = await CartService.pay(accessCode, mainProduct.id, proofProduct.id, payInfo);
            setLoading(false);
            if (data.status !== 200) {
                setErrorMessage(data.message);
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
                return;
            }
            history.push(`/schedule/${accessCode}`);
            dispatch(clearCartState());
        } catch {
            setLoading(false);
        }
    };

    const updateCardNumber = ({ target }) => {
        setPayInfo({ ...payInfo, cardInfo: { ...payInfo.cardInfo, cardNumber: target.value } });
    };

    const updateCardCode = ({ target }) => {
        setPayInfo({ ...payInfo, cardInfo: { ...payInfo.cardInfo, cardCode: target.value } });
    };

    const updateCardExpDate = ({ target }) => {
        setPayInfo({ ...payInfo, cardInfo: { ...payInfo.cardInfo, expDate: target.value } });
    };

    if (componentLoading) return <Loading small />

    return (
        <div className={styles.PayCart}>
            <NavActions onGoBack={() => { history.replace("/cart") }} />
            <Title>Credit card information:</Title>
            <form className={`${styles.Row} ${styles.CreditCardInfo}`}>
                <Input label="Card number: *" title="Card number" value={payInfo.cardInfo.cardNumber} onChange={updateCardNumber} placeholder="0000000000000000" maxLength="16" />
                <div className={styles.Row}>
                    <Input label="CVV: *" title="Card code" value={payInfo.cardInfo.cardCode} onChange={updateCardCode} placeholder="***" maxLength="4" />
                    <Input label="Exp date: *" title="Please format date to MM/YY." value={payInfo.cardInfo.expDate} onChange={updateCardExpDate} placeholder="MM/YY" pattern="[\d]{2}/[\d]{2}" maxLength="5" />
                </div>
            </form>
            <Title>Billing address information:</Title>
            <AddressForm
                initialState={payInfo}
                onInitialStateUpdate={setPayInfo}
                onSubmit={onPay}
                onSubmitText={`Pay $${validTotal}`}
                loading={isLoading}
                buttonLarge={true}>
                <div className={styles.ReservationTimer}>Appointment reservation valid for:<AppointmentHoldTimer /></div>
                {
                    errorMessage ?
                        <div className={styles.PayError}>{errorMessage}</div>
                        :
                        null
                }
            </AddressForm>
            <div>
            </div>
        </div>
    )
}
