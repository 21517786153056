import { createSlice } from "@reduxjs/toolkit";

const emptyInitialState = {
    // list: [{ type: "info", content: "Coupon has expired." }]
    list: []
};

export const cart = createSlice({
    name: 'cart',
    initialState: { ...emptyInitialState },
    reducers: {
        addNotification(state, action) {
            const { content } = action.payload;
            state.list = state.list.filter(item => item.content !== content);
            if (state.list.length > 3) state.list.shift();
            state.list.push(action.payload);
        },
        popNotification(state) {
            if (state.list.length > 0) {
                state.list.shift();
            }
        },
        removeNotification(state, action) {
            const { content } = action.payload;
            state.list = state.list.filter(item => item.content !== content);
        }
    }
});

export const { addNotification, popNotification, removeNotification } = cart.actions;

export default cart.reducer;