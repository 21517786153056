import React from 'react';
import Loading from '../Loading/Loading';

import styles from "./Button.module.css";

export default function Button({ children, isLoading, large, className, clear, blue, danger, onClick, ...props }) {
    return (
        <button disabled={isLoading}
            className={`${styles.Button} 
            ${className ? className : ''} 
            ${large ? styles.IsLarge : ''} 
            ${clear ? styles.IsClear : ''} 
            ${blue ? styles.IsBlue : ''} 
            ${danger ? styles.IsDanger : ''}`}
            onClick={() => {
                if (isLoading) return;
                if (onClick) onClick();
            }} {...props}>
            {children}
            {
                isLoading &&
                <div className={styles.IsLoading} >
                    <Loading small white={blue} />
                </div>
            }
        </button>);
}
