import React from 'react';
import { Link } from 'react-router-dom';

import styles from "./Card.module.css";

export default function Card({ children, ...props }) {
    if (props.to) {
        return <Link className={styles.Card} {...props}>{children}</Link>;
    } else {
        return <div className={styles.Card} {...props}>{children}</div>;
    }
}
