import { createSlice } from "@reduxjs/toolkit";
import ProductConstants from "utils/ProductConstants";

export const LS_ACCESSCODE_KEY = "schleoaccesscode";

const initialState = {
    products: [],
    accessCode: '',
    appointmentInfo: {},
    couponInfo: {},
    lastUpdated: Date.now()
};

const accessCode = localStorage.getItem(LS_ACCESSCODE_KEY) || '';

export const cart = createSlice({
    name: 'cart',
    initialState: { ...initialState, accessCode },
    reducers: {
        setCartState(state, action) {
            const { payload } = action;
            state.accessCode = payload.accessCode;
            state.products = payload.products;

            if (payload.appointmentInfo && Object.keys(payload.appointmentInfo).length > 0) {
                const holdAt = Date.parse(payload.appointmentInfo.holdAt);
                const timeLeft = (60000 * 15) - (Date.now() - holdAt);
                if (timeLeft > 1000) {
                    state.appointmentInfo = { ...payload.appointmentInfo, holdAt: holdAt };
                } else {
                    state.appointmentInfo = {};
                }
            } else {
                state.appointmentInfo = {};
            }
            state.couponInfo = payload.couponInfo;
            localStorage.setItem(LS_ACCESSCODE_KEY, payload.accessCode);
        },
        clearCartState(state) {
            state.accessCode = initialState.accessCode;
            state.products = initialState.products;
            state.couponInfo = initialState.couponInfo;
            state.total = initialState.total;
            state.lastUpdated = Date.now();
            localStorage.removeItem(LS_ACCESSCODE_KEY);
        },
        setAppointmentInfo(state, action) {
            state.appointmentInfo = { ...action.payload, holdAt: Date.now() };
        },
        clearAppoitmentInfo(state) {
            state.appointmentInfo = {};
        },
        setAccessCode(state, action) {
            state.accessCode = action.payload;
            if (action.payload.trim().length !== 0) {
                localStorage.setItem(LS_ACCESSCODE_KEY, action.payload);
            } else {
                localStorage.removeItem(LS_ACCESSCODE_KEY);
            }
        },
        addProduct(state, action) {
            const { product, type } = action.payload;
            const mainProduct = state.products.filter(
                product => product.packagePlanNumber.toUpperCase() === ProductConstants.getPackagePlanNumber("main")
            )[0];
            const proofProduct = state.products.filter(
                product => product.packagePlanNumber.toUpperCase() === ProductConstants.getPackagePlanNumber("proof")
            )[0];

            if (type === "main" && mainProduct?.id !== product.id) {
                state.appointmentInfo = {};
                state.couponInfo = {};
                state.products = proofProduct ? [product, proofProduct] : [product];
            }

            if (type === "proof" && proofProduct?.id !== product.id) {
                state.couponInfo = {};
                state.products = mainProduct ? [mainProduct, product] : [product];
            }
        },
        removeProduct(state, action) {
            const { type } = action.payload;
            state.products = state.products.filter(
                cartProduct => cartProduct.packagePlanNumber.toUpperCase() !== ProductConstants.getPackagePlanNumber(type)
            );
            if (type !== "proof") state.appointmentInfo = {};
        },
        setCouponInfo(state, action) {
            state.couponInfo = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        }
    },
    extraReducers: (builder) => {

    }
});

export const {
    setCartState,
    clearCartState,
    setAppointmentInfo,
    clearAppoitmentInfo,
    setAccessCode,
    addProduct,
    removeProduct,
    addCouponCode,
    setCouponInfo,
    setTotal
} = cart.actions;

export default cart.reducer;