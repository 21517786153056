import React from 'react';
import CustomLink from 'components/UI/CustomLink';

import styles from './AccessCodeError.module.css';

export default function AccessCodeError({ visible, accessCode, ...props }) {
    if (visible) {
        return (
            <div className={styles.AccessCodeError}>
                Information for {accessCode} not found. You can try to <CustomLink to="/schedule/findaccesscode">find access code</CustomLink> or contact customer service 800-215-4852
            </div>
        )
    } else {
        return null;
    }
}
