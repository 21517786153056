import React, { useState, useEffect, useRef, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import styles from "./Input.module.css";

export default function Input({ label, isValid = true, validateOnInput = true, title = '', onInput, center, autofocus = false, ...props }) {
    const inputRef = useRef(null);
    const timeoutId = useRef('');
    const [validity, setValidity] = useState({ isValid: true, message: title });
    const id = props.id || uuid();

    const handleInput = (ev) => {
        if (validateOnInput) validityCheck();
        onInput && onInput(ev);

        timeoutId.current && clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
            setValidity({ isValid: true, message: "" });
        }, 3000);
    };

    const validityCheck = useCallback(() => {
        inputRef.current.setCustomValidity("");
        setValidity({ isValid: true });

        if (inputRef.current.validity.patternMismatch) {
            setValidity({ isValid: false, message: title });
            return;
        }

        if (inputRef.current.validity.tooShort) {
            setValidity({
                isValid: false,
                message: `Please use ${inputRef.current.minLength} or more characters.`
            });
            return;
        }

        if (inputRef.current.validity.tooLong) {
            setValidity({
                isValid: false,
                message: `Please use ${inputRef.current.maxLength} or less characters.`
            });
            return;
        }

        setValidity({ isValid: inputRef.current.checkValidity(), message: inputRef.current.validationMessage });

        if (inputRef.current.type === "password") {
            inputRef.current.value.match("(?=.*[a-z])") || setValidity({ isValid: false, message: "Please include at least one lowercase character." });
            inputRef.current.value.match("(?=.*[A-Z])") || setValidity({ isValid: false, message: "Please include at least one uppercase character." });
            inputRef.current.value.match("(?=.*\\d)") || setValidity({ isValid: false, message: "Please include at least one digit." });
        }

        if (!isValid && inputRef.current.value) {
            inputRef.current.setCustomValidity(title);
            setValidity({ isValid: false, message: title });
        }
    }, [isValid, title]);


    useEffect(() => {
        if (inputRef.current.value) validityCheck();
        if (autofocus) inputRef.current.focus();
        return () => {
            clearTimeout(timeoutId.current);
        };
    }, [timeoutId, validityCheck, autofocus]);

    return (
        <div className={`${styles.InputComponent} ${center ? styles.IsCenter : ''}`}>
            {
                label ?
                    <label htmlFor={id}> {label}</label>
                    :
                    null
            }
            <input id={id} className={styles.Input} onInput={handleInput} autoComplete='off' {...props} ref={inputRef} />

            {
                (!validity.isValid && validity.message) &&
                <div className={`${styles.Validation} ${styles.Invalid}`}>
                    {validity.message}
                </div>
            }
        </div>);
}
