import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAppoitmentInfo, addCouponCode } from 'store/cart';
import { addNotification } from 'store/notification';

import styles from "./AppointmentHoldTimer.module.css";

export default function AppointmentHoldTimer() {
    const dispatch = useDispatch();
    const minInMs = 60000;
    const { appointmentInfo } = useSelector(state => state.cart);
    const [timeLeft, setTimeLeft] = useState(0);

    useLayoutEffect(() => {
        if (appointmentInfo && Object.keys(appointmentInfo) !== 0) {
            const initalTimeLeft = Math.floor(((minInMs * 15) - (Date.now() - appointmentInfo.holdAt)) / 1000);
            setTimeLeft(initalTimeLeft);
            const id = (setInterval(() => {
                const newTimeLeft = Math.floor(((minInMs * 15) - (Date.now() - appointmentInfo.holdAt)) / 1000);
                if (newTimeLeft < 1) {
                    dispatch(clearAppoitmentInfo());
                    dispatch(addCouponCode(""));
                    const message = { type: "info", content: `The reservation for your selected appointment time has expired. Please select an available time.`, linkTo: "/cart/find-time", linkText: "find time" };
                    dispatch(addNotification(message));
                } else {
                    setTimeLeft(newTimeLeft);
                }
            }, 1000));
            return () => {
                clearInterval(id);
            };
        }
    }, [dispatch, appointmentInfo]);

    if (!appointmentInfo || Object.keys(appointmentInfo) === 0 || !appointmentInfo.holdAt) {
        return null;
    }

    if (timeLeft === 0) {
        return null;
    } else {
        return (
            <span className={styles.Timer}>{parseInt(timeLeft / 60)}:{parseInt(timeLeft % 60) < 10 ? `0${parseInt(timeLeft % 60)}` : parseInt(timeLeft % 60)}</span>
        )
    }
}
