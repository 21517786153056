import React from 'react';
import Card from 'components/UI/Card';

import styles from './AccessCode.module.css';

export default function AccessCode({ accessCode }) {
    return (
        <Card to={`/schedule/${accessCode.code}`}>
            <div className={styles.AccessCodeElement}>
                <div>
                    <div className={styles.Name}>
                        <span>{accessCode.firstName ? accessCode.firstName : ''}</span>
                        <span>{accessCode.lastName ? accessCode.lastName : ''}</span>
                    </div>
                    <div className={styles.StudentNumber}>
                        {accessCode.studentNumber ? accessCode.studentNumber : ''}
                    </div>
                </div>
                <div className={styles.AccessCode}>
                    {accessCode.code ? accessCode.code : ''}
                </div>
            </div>
        </Card>);
}
