
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import NavActions from 'components/UI/NavActions';
import Title from 'components/UI/Title';
import Loading from 'components/UI/Loading';
import SubTitle from 'components/UI/SubTitle';
import StudioCard from 'components/UISchedule/StudioCard';
import SelectTime from 'components/UISchedule/SelectTime';
import SelectDate from 'components/UISchedule/SelectDate';

import styles from './FindTime.module.css';

export default function FindTime({ accessCode, mainProductId, count, contentOnly = false, scheduleType = "new" }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedStudio, setSelectedStudio] = useState({});
    const [studios, setStudios] = useState([]);
    const [isDataLoading, setDataLoading] = useState(true);
    const [yearbookSelectionDate, setYearbookSelectionDate] = useState("");
    const [yearbookCutoffDate, setYearbookCutoffDate] = useState("");

    useEffect(() => {
        if (count !== 2) {
            history.push("/cart");
            return;
        }
        const fetchStudios = async () => {
            setDataLoading(true);
            const { data } = await SeniorsScheduleService.getStudios(accessCode, mainProductId);
            if (data.status !== 200) {
                console.log("error");
            }
            const sortedStudios = data.studios.sort((firstEl, secondEl) => {
                //If one of elements default studio - that element always first.
                if (firstEl.isDefaultStudio && !secondEl.isDefaultStudio) {
                    return -1;
                }
                //If one of elements is school but noone of them is default studio - that element first.
                if (firstEl.isSchool && !secondEl.isSchool) {
                    return -1;
                }
                // In all of other cases they are th same.
                return 0;
            });
            setStudios(sortedStudios);
            if (sortedStudios.length === 1) setSelectedStudio(sortedStudios[0]);
            setYearbookSelectionDate(data.yearbookSelectionLastDate);
            setYearbookCutoffDate(data.cutoffDate);
            setDataLoading(false);
        };
        fetchStudios();
    }, [dispatch, history, accessCode, mainProductId, count]);

    const onSetSelectedStudio = async (contractNumber) => {
        setSelectedDate("");
        setSelectedStudio({});
        if (contractNumber !== selectedStudio.contractNumber) {
            const selectedStudio = studios.filter(studio => studio.contractNumber === contractNumber)[0];
            setSelectedStudio(selectedStudio);
        }
    };

    const onSetSelectedDate = useCallback((date) => {
        if (!date) return;

        if (!date instanceof Date) {
            date = new Date(date);
        }
        setSelectedDate(date.toLocaleDateString('en-US'));
    }, []);

    return (
        <div className={styles.FindTime}>
            {
                contentOnly ?
                    null
                    :
                    <Fragment>
                        <NavActions onGoBack={() => { history.replace(scheduleType === "new" ? "/cart" : `/schedule/${accessCode}`) }} />
                        <Title>Find available time:</Title>
                    </Fragment>
            }

            <SubTitle textCenter>Available locations:</SubTitle>
            <div className={styles.Studios}>
                {
                    studios.map((studio) => <StudioCard studio={studio}
                        key={studio.contractNumber}
                        selected={studio.contractNumber === selectedStudio.contractNumber}
                        onClick={() => onSetSelectedStudio(studio.contractNumber)} />)
                }
            </div>

            {isDataLoading ?
                <Loading small />
                :
                <div className={styles.TimeSelector}>
                    {
                        (selectedStudio && Object.keys(selectedStudio).length !== 0 && selectedStudio.contractNumber) &&
                        <Fragment>
                            <div className={styles.SelectDate}>
                                <SubTitle textCenter>Select date:</SubTitle>
                                <SelectDate
                                    contract={selectedStudio.contractNumber}
                                    date={selectedDate}
                                    onSelectDate={onSetSelectedDate}
                                    accessCode={accessCode}
                                    mainProductId={mainProductId} />
                            </div>
                            {
                                selectedDate &&
                                <div className={styles.SelectTime}>
                                    <SubTitle textCenter>Click on time to select:</SubTitle>
                                    <SelectTime
                                        date={selectedDate}
                                        selectedStudio={selectedStudio}
                                        accessCode={accessCode}
                                        mainProductId={mainProductId}
                                        scheduleType={scheduleType} />
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            }
            {
                yearbookCutoffDate ?
                    <Fragment>
                        <div className={styles.DateWarning}>
                            * Your Yearbook pose will be selected for you if portraits are taken after {yearbookSelectionDate}.
                        </div>
                        <div className={styles.DateWarning}>
                            ** Your school's yearbook cutoff date is {yearbookCutoffDate}.
                        </div>
                    </Fragment>
                    :
                    null
            }
        </div>)
}
