import React, { useState, useEffect, Fragment } from 'react';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import ProductImages from 'components/UISchedule/ProductImages';
import ProductService from 'services/ProductService';

import styles from "./ProductCard.module.css";

export default function ProductCard({ productId = null,
    accessCode = "",
    showSelected = true,
    horizontal = false,
    allowAction = true,
    isSelected = false,
    price,
    futures,
    onAddToCart,
    onRemoveFromCart,
    type = "main",
    ...props }) {
    const [product, setProduct] = useState(props.product ? props.product : null);
    const [isProductLoading, setProductLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // Effect to load product info if not provided as a param.
    useEffect(() => {
        if (!productId) return;
        const fetchProduct = async () => {
            setProductLoading(true);
            try {
                const { data } = await ProductService.getProduct(productId, accessCode);
                if (data.status === 200) {
                    setProduct(data.product);
                }
            } catch {
                console.log("Error while loading product info.");
            } finally {
                setProductLoading(false);
            }
        };
        fetchProduct();
    }, [productId, accessCode]);

    // Effect for 'futures' as a param.
    useEffect(() => {
        if (product && Object.keys(product).length !== 0 && futures) {
            const normilizedProductFutures = product.futures.map(future => future.trim().toUpperCase());
            //Check if loaded product futures are different from 'futures' parameter.
            const filteredFutures = futures.filter(future => normilizedProductFutures.includes(future.trim().toUpperCase()));

            if (normilizedProductFutures.length !== filteredFutures.length) {
                setProduct({ ...product, futures });
            }
        }
    }, [product, futures])

    const formatPrice = (price) => {
        const dollars = parseInt(price);
        const cents = parseInt((price - dollars) * 100) + "";
        return `${dollars}.${cents.length === 1 ? cents + "0" : cents}`;
    };

    const handleAddToCart = async () => {
        if (!allowAction) return;
        onAddToCart(product, setLoading, type);
    };

    const handleRemoveFromCart = () => {
        if (!allowAction) return;
        onRemoveFromCart(product, setLoading, type);
    };

    if (product) {
        return (
            <div className={`${styles.ProductCard} ${(isSelected && showSelected) ? styles.IsSelected : ''} ${horizontal ? styles.CardHorizontal : styles.CardVertical}`}>
                <div className={styles.ProductName}>
                    {product.title}
                    {
                        (isSelected && showSelected) &&
                        <div className={styles.IsSelected_Text}>
                            &#9989;
                        </div>

                    }
                </div>
                <div className={`${styles.CardContent} ${horizontal ? styles.ContentHorizontal : styles.ContentVertical}`}>
                    <ProductImages productId={product.id} images={product.images} square={type === "proof"} />
                    <div className={styles.ProductInfo}>
                        <div className={styles.ProductFutures}>
                            {product.futures.map((future, index) => {
                                return (<div className={styles.ProductFuture} key={`${future.replaceAll(" ", "")}${index}`}>
                                    <div className={styles.ProductFuture_Mark}>
                                        <li></li>
                                    </div>
                                    <div className={styles.ProductFuture_Text}>
                                        {future}
                                    </div>
                                </div>);
                            })}
                        </div>
                        {
                            Number.parseFloat(price) || Number.parseFloat(product.totalPrice) ?
                                <div className={`${styles.Price} ${styles.DollarSign}`}>
                                    {price ? formatPrice(price) : formatPrice(product.totalPrice)}
                                </div>
                                :
                                <div className={styles.Price}>
                                    Free
                                </div>
                        }
                        {
                            allowAction &&
                            <Fragment>
                                {
                                    isSelected ?
                                        <Button onClick={handleRemoveFromCart} isLoading={isLoading} danger>
                                            Remove from cart
                                        </Button>
                                        :
                                        <Button onClick={handleAddToCart} isLoading={isLoading} blue>
                                            Add to cart
                                        </Button>
                                }
                            </Fragment>
                        }

                    </div>
                </div>
                {
                    product.asterisks.length > 0
                    &&
                    <div className={styles.Disclamer}>
                        {
                            product.asterisks.reduce((result, asterisk) => result += asterisk.replaceAll("*", ""))
                        }
                    </div>
                }
            </div>
        );
    };

    if (isProductLoading && !product) return (<Loading />);

    return "Product not found.";
}
