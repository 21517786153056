import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import CustomLink from 'components/UI/CustomLink';
import Title from 'components/UI/Title';
import SeniorsScheduleService from 'services/SeniorsScheduleService';

import styles from "./SelectAccessCode.module.css";
import AccessCodeError from 'components/UISchedule/AccessCodeError/AccessCodeError';

export default function SelectAccessCode() {
    const history = useHistory();
    const [providedAccessCode, setAccessCode] = useState('');
    const [accessCodeNotFound, setAccessCodeNotFound] = useState(false);

    const updateAccessCode = (ev) => {
        setAccessCode(ev.target.value);
    };

    const confirmAccessCode = async (ev) => {
        ev.preventDefault();

        if (ev.target.reportValidity()) {
            const { data } = await SeniorsScheduleService.getAccessCodeInfo(providedAccessCode);
            if (data.status !== 200) {
                setAccessCodeNotFound(true);
            } else {
                history.push(`/schedule/${providedAccessCode.trim().replaceAll("-", "")}`);
            }
        }
    };

    return (
        <div className={styles.SelectAccessCode}>
            <Title>
                Please provide your access code:
            </Title>
            <div className={styles.ScheduleContent}>
                <form onSubmit={confirmAccessCode} className={styles.AccessCode}>
                    <Input center
                        type='text'
                        placeholder='Access code'
                        minLength='10'
                        maxLength='15'
                        value={providedAccessCode}
                        onInput={updateAccessCode} />
                    {
                        providedAccessCode &&
                        <div className={styles.Confirm}>
                            <Button type='submit' title='Confirm access code' clear>OK</Button>
                        </div>
                    }
                </form>
                <div className={styles.Or}>or</div>
                <div className={styles.FindCode}>
                    <CustomLink to='/schedule/findaccesscode' className={styles.FindCodeLink}>find access code</CustomLink>
                </div>
            </div>
            <AccessCodeError visible={accessCodeNotFound} accessCode={providedAccessCode} />
        </div>);
}
