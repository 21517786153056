import React, { useState } from 'react';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';

import styles from "./ProofBookUpsellModal.module.css";

export default function ProofBookUpsellModal({ isOpen = false, onSuccess, onDeny }) {
    const proofBookImageSrc = process.env.REACT_APP_URL + "/images/prfbook400.jpg";
    const [isSuccessLoading, setSuccessLoading] = useState(false);
    const [isDenyLoading, setDenyLoading] = useState(false);

    return (
        <Modal isOpen={isOpen}>
            <div className={styles.ModalBody}>
                <div className={styles.ModalBody_Image}>
                    <img src={proofBookImageSrc} style={{ maxWidth: "100%" }} alt='Proof book upsell' />
                </div>
                <div className={styles.ModalBody_Sale}>
                    <h2>
                        Upgrade to our Senior Preview Book to receive $50 off your final Senior Photo order!
                    </h2>
                    <p>
                        Our spiral bound Preview Books are great way to view all of your Senior’s images in person before purchasing your final products!
                    </p>
                    <div className={styles.ModalBody_SaleActions}>
                        <Button
                            blue
                            large
                            onClick={() => { onSuccess(setSuccessLoading) }}
                            isLoading={isSuccessLoading}>UPGRADE ME FOR $50</Button>
                        <Button
                            clear
                            style={{ marginTop: ".5rem", textDecoration: "underline" }}
                            isLoading={isDenyLoading}
                            onClick={() => { onDeny(setDenyLoading) }}>No, thanks.</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
