import React from 'react';

import styles from "./Loading.module.css"

export default function Loading({ small, white }) {
    return (
        <div className={`${styles.Loading}`}>
            <div className={`${styles.Ring} ${small ? styles.IsSmall : ""} ${white ? styles.IsWhite : ""}`}>
                <span className={styles.Text}>Loading</span>
            </div>
        </div>);
}
