import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import Loading from 'components/UI/Loading';
import FindTime from 'components/Routes/Cart/FindTime';

import styles from './Reschedule.module.css';

export default function Reschedule() {
    const history = useHistory();
    const { accesscode } = useParams();
    const [schedule, setSchedule] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSchedule = async () => {
            setLoading(true);
            try {
                const { data } = await SeniorsScheduleService.loadSchedule(accesscode);
                if (data.schedule && Object.keys(data.schedule).length > 0) {
                    setSchedule(data.schedule);
                } else {
                    history.goBack();
                }
                setLoading(false);
            } catch {
                setLoading(false);
            }
        }
        fetchSchedule();
    }, [history, accesscode]);

    if (isLoading) return <Loading small />

    return (
        <div className={styles.Reschedule}>
            <FindTime accessCode={schedule.accessCode} mainProductId={schedule.mainProductId} count={schedule.count} scheduleType="reschedule" />
        </div>
    )
}
