import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'store/notification';
import CustomLink from 'components/UI/CustomLink';

import styles from './Notifications.module.css';

export default function Notifications() {
    const dispatch = useDispatch();
    const { list } = useSelector(state => state.notification);

    if (list.length === 0) return null;

    return (
        <div className={styles.Notifications}>
            {
                list.map(item => (<div key={item.content.replaceAll(" ", "").slice(0, 20)} className={styles.Notification}>
                    {item.content}
                    {item.linkTo && item.linkText ? <div onClick={() => { dispatch(removeNotification(item)) }}><CustomLink to={item.linkTo}>{item.linkText}</CustomLink></div> : null}
                    <div className={styles.CloseBtn} onClick={() => { dispatch(removeNotification(item)) }}>&#10005;</div>
                </div>))
            }
        </div>
    )
}
