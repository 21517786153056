import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { register, logout } from 'store/auth';
import { setAccessCode, clearCartState } from 'store/cart';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

import styles from './Register.module.css';

export default function Register() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isAuth, isAuthLoading } = useSelector(state => state.auth);
    const [isLoading, setLoading] = useState(false);
    const initalRegistrationData = {
        email: '', password: '', passwordConfirmation: '', firstName: '', lastName: ''
    };
    const [registrationData, setRegistrationData] = useState(initalRegistrationData);
    const [passwordCheck, setPasswordCheck] = useState({ isMatching: true, message: "Passwords do not match." });
    const [message, setMessage] = useState('');

    const setEmail = (ev) => {
        setRegistrationData({ ...registrationData, email: ev.target.value.trim() });
    };

    const setPassword = (ev) => {
        setRegistrationData({ ...registrationData, password: ev.target.value.trim() });
    };

    const setPasswordConfirmatioin = (ev) => {
        setRegistrationData({ ...registrationData, passwordConfirmation: ev.target.value.trim() });
        if (registrationData.password === ev.target.value.trim()) {
            setPasswordCheck({ ...passwordCheck, isMatching: true });
        } else {
            setPasswordCheck({ ...passwordCheck, isMatching: false });
        }
    };

    const setFirstName = (ev) => {
        setRegistrationData({ ...registrationData, firstName: ev.target.value.trim() });
    };

    const setLastName = (ev) => {
        setRegistrationData({ ...registrationData, lastName: ev.target.value.trim() });
    };

    const handleRegisterSubmit = async (ev) => {
        ev.preventDefault();
        if (!ev.target.reportValidity()) {
            return;
        }

        setLoading(true);
        const { payload } = await dispatch(register(registrationData));
        setLoading(false);

        if (!payload.isSuccess) {
            setRegistrationData({ ...registrationData, passwordConfirmation: '' });
            setMessage(payload.message);
            ev.target.timoutId && clearTimeout(ev.target.timoutId);
            ev.target.timoutId = setTimeout(() => {
                setMessage('');
            }, 2000);
        };
    };

    useEffect(() => {
        if (isAuth) {
            if (history.length <= 1) {
                history.push("/schedule");
            } else {
                history.goBack();
            }
            return;
        }
    }, [history, isAuth]);

    useEffect(() => {
        if (!isAuth && !isAuthLoading && !isLoading) {
            dispatch(setAccessCode(""));
            dispatch(clearCartState());
            dispatch(logout());
        }
    }, [dispatch, isAuth, isAuthLoading, isLoading])

    return (
        <div className={styles.Register}>
            <div className={styles.RegisterContent}>
                <div className={styles.WelcomeText}>Welcome!</div>
                Please register:
                <form className={styles.RegisterForm} onSubmit={handleRegisterSubmit}>
                    <Input
                        type='email'
                        placeholder='Email *'
                        value={registrationData.email}
                        onInput={setEmail}
                        maxLength={255}
                        required />
                    <Input
                        type='password'
                        placeholder='Password *'
                        value={registrationData.password}
                        minLength={8}
                        maxLength={40}
                        onInput={setPassword} required />
                    <Input
                        type='password'
                        placeholder='Confirm password *'
                        value={registrationData.passwordConfirmation}
                        onInput={setPasswordConfirmatioin}
                        isValid={passwordCheck.isMatching}
                        title={passwordCheck.isMatching || passwordCheck.message}
                        required />
                    <Input
                        type='text'
                        placeholder='First name *'
                        value={registrationData.firstName}
                        onInput={setFirstName}
                        required />
                    <Input
                        type='text'
                        placeholder='Last name *'
                        value={registrationData.lastName}
                        onInput={setLastName}
                        required />
                    {
                        message &&
                        <div className={styles.Validation}>
                            {message}
                        </div>
                    }
                    <Button type='submit' isLoading={isLoading}>Register</Button>
                </form>
                <div className={styles.Signin}>
                    Already have an account?
                    <Link replace={true} to="/signin">Sign in</Link>
                </div>
            </div>
        </div>);
}
