
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import Title from 'components/UI/Title';
import NavActions from 'components/UI/NavActions';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Loading from 'components/UI/Loading';
import AccessCode from 'components/UISchedule/AccessCode';

import styles from "./FindAccessCode.module.css";

export default function FindAccessCode() {
    const history = useHistory();
    const [accessCodes, setAccessCodes] = useState([]);
    const [searchParams, setSearchParams] = useState({ lastName: '', studentNumber: '' });
    const [isLoading, setLoading] = useState(false);
    const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);

    const setLastName = (ev) => {
        setSearchParams({ ...searchParams, lastName: ev.target.value.trim() });
    };

    const setStudentNumber = (ev) => {
        setSearchParams({ ...searchParams, studentNumber: ev.target.value.trim() });
    };

    const searchCodes = async (ev) => {
        ev.preventDefault();
        if (ev.target.reportValidity()) {
            setLoading(true);
            setShowNotFoundMessage(false);
            try {
                const res = await SeniorsScheduleService.searchAccessCodes(searchParams.lastName, searchParams.studentNumber);
                if (res.data.status === 200) {
                    const codes = res.data.accessCodes;
                    setAccessCodes([...codes]);
                    setShowNotFoundMessage(codes && (codes.length > 1 || codes.length === 0));
                }
            } catch {
                setAccessCodes([]);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className={styles.FindAccessCode}>
            <NavActions onGoBack={() => { history.replace("/schedule") }} />
            <Title>
                Find access code:
            </Title>
            <form onSubmit={searchCodes} >
                <div className={styles.Inputs}>
                    <Input value={searchParams.lastName} onInput={setLastName} placeholder='Last name *' required />
                    <Input value={searchParams.studentNumber} onInput={setStudentNumber} minLength='3' placeholder='Student number *' required />
                </div>
                <Button type='submit' isLoading={isLoading}>Find</Button>
            </form>

            <div className={styles.AccessCodes}>
                {
                    isLoading && <Loading small />
                }
                {
                    showNotFoundMessage && !isLoading ?
                        <div>
                            No access codes found.
                            <br /> Looks like we are still waiting on a few things from your school! Check back in about a week to find your access code!
                            <br /> For other issues contact customer service:
                            <div style={{ marginTop: ".375rem" }}>
                                <a href={"mailto:" + process.env.REACT_APP_CS_EMAIL}><strong>{process.env.REACT_APP_CS_EMAIL}</strong></a>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    accessCodes.length === 1 &&
                    !isLoading &&
                    accessCodes.map((code, index) => <AccessCode key={index} accessCode={code} />)
                }
            </div>
        </div >);
}
