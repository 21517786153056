import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import Calendar from 'react-calendar';
import Loading from 'components/UI/Loading'
import SeniorsScheduleService from 'services/SeniorsScheduleService';

import 'react-calendar/dist/Calendar.css';
import './CalendarStyles.css';
import styles from './SelectDate.module.css';

export default function SelectDate({ contract, date, onSelectDate, accessCode, mainProductId }) {
    const componentRef = useRef(null);
    const dispatch = useDispatch();
    const todayDate = new Date();
    const maxDate = new Date(todayDate.setMonth(todayDate.getMonth() + 3));
    const [minDate, setMinDate] = useState(new Date());
    const [datesWithOpenTimeSlots, setDatesWithOpenTimeSlots] = useState([]);
    const [isComponentLoading, setComponentLoading] = useState(true);

    useEffect(() => {
        let updateAllowed = true;
        const fetchAmountOfAvailableSlots = async () => {
            if (!contract) return;
            setComponentLoading(true);
            setDatesWithOpenTimeSlots([]);
            componentRef.current.scrollIntoView({ behavior: "smooth" });
            const { data } = await SeniorsScheduleService.getAmountOfStudioTimeSlots(accessCode, contract, mainProductId);
            if (updateAllowed) {
                const dates = data.slots.map(item => new Date(item.date));
                if (dates.length === 0) onSelectDate(null);
                setMinDate(dates[0]);
                setDatesWithOpenTimeSlots(dates.map(date => date.toDateString()));
                setComponentLoading(false);
            }
        };
        fetchAmountOfAvailableSlots();
        return () => { updateAllowed = false };
    }, [dispatch, accessCode, contract, mainProductId, onSelectDate])

    const onDateChange = (date) => {
        onSelectDate(date);
    };

    return (
        <div className={`${styles.SelectDate} select-date`} ref={componentRef}>
            {
                datesWithOpenTimeSlots.length > 0 && !isComponentLoading
                    ?
                    <Calendar
                        value={date ? new Date(date) : null}
                        onChange={onDateChange}
                        className={styles.Calendar}
                        defaultActiveStartDate={minDate}
                        minDate={new Date()}
                        maxDate={maxDate}
                        next2Label={null}
                        prev2Label={null}
                        defaultView='month'
                        minDetail='month'
                        maxDetail='month'
                        calendarType='US'
                        locale='en-US'
                        tileDisabled={({ date }) => !datesWithOpenTimeSlots.includes(date.toDateString())} />
                    :
                    "No dates available."
            }
            {
                isComponentLoading
                &&
                <div className={styles.CalendarLoading}><Loading small /></div>
            }
        </div>
    )
}
