import React from 'react';

import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';

export default function ExtraPoseYearbookPlusModal({ isOpen, setOpen }) {
    const monthNum = new Date().getMonth() + 1;
    const dayNum = new Date().getDay() + 1;

    if (monthNum > 7 || (monthNum === 7 && dayNum > 31)) return null;

    return (
        <Modal isOpen={isOpen}>
            <div style={{ padding: "2rem", maxWidth: "500px", textAlign: "center" }}>
                <h2 style={{ marginTop: "0" }}>IMPORTANT INFORMATION</h2>
                <p>You must be scheduled and photographed by <strong>July 31st</strong> to receive the indoor scene as part of your Yearbook Plus Session.</p>
                <Button blue onClick={() => { setOpen(false); }}>I UNDERSTAND</Button>
            </div>
        </Modal>
    )
}
