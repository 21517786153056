import React, { Fragment } from 'react';
import StudioCard from "components/UISchedule/StudioCard";
import CustomLink from 'components/UI/CustomLink';

import styles from "./AppointmentInfo.module.css";

export default function AppointmentInfo({ info: appointmentInfo, children }) {
    return (
        <div className={styles.AppointmentInfo}>
            {
                !appointmentInfo.selectedSudio && !appointmentInfo.date && !appointmentInfo.slot
                    ?
                    <div className={styles.NoAppointmentInfo}>
                        Click link below to find and select appointment time
                        <CustomLink large to={`/cart/find-time`}>find available time</CustomLink>
                    </div>
                    :
                    <Fragment>
                        <div className={styles.InfoItem}>
                            <div className={styles.InfoItem_Title}>Location:</div>
                            <StudioCard studio={appointmentInfo.selectedStudio} viewOnly={true} />
                        </div>
                        <div className={styles.InfoItem}>
                            <div className={styles.InfoItem_Title}>Date:</div>
                            <div className={styles.InfoItem_Content}>{appointmentInfo.date}</div>
                        </div>
                        <div className={styles.InfoItem}>
                            <div className={styles.InfoItem_Title}>Time:</div>
                            <div className={styles.InfoItem_Content}>{appointmentInfo.slot}</div>
                        </div>
                        {children
                            &&
                            <div className={styles.ChangeButton}>
                                {children}
                            </div>
                        }
                    </Fragment>
            }
        </div>
    )
}
