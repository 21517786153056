import React from 'react';

import styles from './StudioCard.module.css';

export default function StudioCard({ studio, viewOnly = false, selected, ...props }) {
    return (
        <div className={`${styles.StudioCard} ${(selected && !viewOnly) ? styles.IsSelected : ""}`} {...props}>
            {
                viewOnly ?
                    null
                    :
                    <input type="checkbox" checked={selected} onChange={() => { }} />
            }
            <div>
                <div className={styles.StudioName}>{studio.name}</div>
                <div className={styles.StudioAddress}>
                    {studio.location}
                </div>
            </div>
        </div>
    )
}
