import axios from "axios";
import { refresh } from "store/auth";

export const API_URL = process.env.REACT_APP_API_URL;
export const TOKEN_NAME = "hawk-api-token";

let store;
export const injectStore = _store => { store = _store };

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
});

$api.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN_NAME);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

$api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const refreshRes = await store.dispatch(refresh());
                if (refreshRes.payload.isSuccess) return $api.request(originalRequest);
            } catch (e) {
                console.log(`Interceptor response: ${e}`);
            }
        }
        throw error;
    });

export default $api;