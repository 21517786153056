import api from "http/index";

class ProductService {
    async getProduct(productId, accessCode) {
        return api.get(`SeniorsSchedule/Product/${productId}/${accessCode}`);
    };

    async getProducts(accessCode, type = "main") {
        return api.get(`SeniorsSchedule/Products/${accessCode}/${type}`);
    };

    async getProductImages(productId) {
        return api.get(`SeniorsSchedule/Product/Images/${productId}`);
    };
}

export default new ProductService();