import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { signinWithGuid } from 'store/auth';

export default function GuidAuth() {
    let { guid } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { isAuth } = useSelector(state => state.auth);
    const { search } = useLocation();

    useEffect(() => {
        const auth = async () => {
            const accessCode = new URLSearchParams(search).get("accesscode");
            if (guid) {
                await dispatch(signinWithGuid(guid));

                if (accessCode) {
                    history.push("/schedule/" + accessCode);
                } else {
                    history.push("/schedule");
                }

                return;
            }

        };
        auth();
    }, [dispatch, guid, history, isAuth, search]);

    return null;
}
