import api from "http/index";

class CartService {
    async addProductToCart(accessCode, productCode) {
        return api.get(`SeniorsSchedule/${accessCode}/Cart/Product/${productCode}`);
    }

    async removeProductFromCart(productCode) {
        return api.delete(`SeniorsSchedule/Cart/Product/${productCode}`);
    }

    async checkout(accessCode, sessionCode, proofingCode) {
        const data = new FormData();

        data.append("AccessCode", accessCode);
        data.append("SeniorsSessionCode", sessionCode);
        data.append("ProofingOptionCode", proofingCode);

        return api.post(`SeniorsSchedule/Checkout`, data);
    }

    async pay(accessCode, sessionCode, proofingCode, paymentInfo) {
        const data = new FormData();

        data.append("AccessCode", accessCode);
        data.append("SeniorsSessionCode", sessionCode);
        data.append("ProofingOptionCode", proofingCode);
        data.append("CardNumber", paymentInfo.cardInfo.cardNumber);
        data.append("CardCode", paymentInfo.cardInfo.cardCode);
        data.append("ExpDate", paymentInfo.cardInfo.expDate);

        data.append("BillingAddress.FirstName", paymentInfo.firstName);
        data.append("BillingAddress.LastName", paymentInfo.lastName);
        data.append("BillingAddress.Email", paymentInfo.email);
        data.append("BillingAddress.PhoneNumber", paymentInfo.phone);
        data.append("BillingAddress.Address1", paymentInfo.address1);
        data.append("BillingAddress.Address2", paymentInfo.address2);
        data.append("BillingAddress.City", paymentInfo.city);
        data.append("BillingAddress.State", paymentInfo.state);
        data.append("BillingAddress.ZipCode", paymentInfo.zipCode);
        data.append("BillingAddress.type", "billing");

        return api.post(`SeniorsSchedule/Pay`, data);
    }

    async loadCouponInfo(coupon) {
        const urlParams = new URLSearchParams();
        urlParams.append("coupon", coupon);
        return api.get(`SeniorsSchedule/Coupon?` + urlParams);
    }

    async applyCoupon(accessCode, coupon) {
        const urlParams = new URLSearchParams();
        urlParams.append("coupon", coupon);
        urlParams.append("accessCode", accessCode);

        return api.get(`SeniorsSchedule/Coupon/Apply?` + urlParams);
    }
}

export default new CartService();