import React from 'react';
import ReactDOM from 'react-dom';
import Store from 'store';
import { Provider } from 'react-redux';
import App from 'components/App';
import { injectStore } from 'http';

injectStore(Store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);