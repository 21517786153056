import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAccessCode } from 'store/cart';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';
import SubTitle from 'components/UI/SubTitle';
import NavActions from 'components/UI/NavActions';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import ViewSchedule from 'components/Routes/Schedule/ViewSchedule';
import UpdateAccessCodeInfo from 'components/Routes/Schedule/UpdateAccessCodeInfo';
import SelectProduct from 'components/Routes/Schedule/SelectProduct';

export default function AccessCodeRoute() {
    let { path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { accesscode } = useParams();
    const [schedule, setSchedule] = useState({});
    const [accessCodeInfo, setAccessCodeInfo] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSchedule = async () => {
            setLoading(true);
            try {
                const { data } = await SeniorsScheduleService.loadSchedule(accesscode);

                if (data.schedule && Object.keys(data.schedule).length > 0 && data.schedule.appointmentInfo.date) {
                    setSchedule(data.schedule);
                    history.push(`/schedule/${accesscode}`);
                    dispatch(setAccessCode(""));
                }

                if (data.accessCodeInfo && Object.keys(data.accessCodeInfo).length > 0) {
                    setAccessCodeInfo(data.accessCodeInfo);
                }
                setLoading(false);
            } catch {
                setLoading(false);
            }
        }

        if (!schedule || Object.keys(schedule).length === 0) {
            fetchSchedule();
        }
    }, [dispatch, history, accesscode, schedule])

    if (isLoading) return <Loading small />;

    if (!accessCodeInfo.scheduleAllowed) {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <NavActions onGoBack={() => { history.replace("/schedule") }} />
                <Title>
                    Schedule is not allowed.
                </Title>
                <SubTitle textCenter>
                    Your yearbook cutoff date has passed. Yearbook cutoff date: {accessCodeInfo.cutoffDate}
                </SubTitle>
            </div>
        );
    }

    return (
        <Fragment>
            {
                (schedule && Object.keys(schedule).length > 0 && !isLoading) ?
                    <ViewSchedule accessCode={accesscode} schedule={schedule} />
                    :
                    <Switch>
                        <Route exact path={`/schedule/:accesscode`}>
                            <UpdateAccessCodeInfo />
                        </Route>
                        <Route exact path={`${path}/sessions`}>
                            <SelectProduct type="main" title="Select your session:" />
                        </Route>
                        <Route exact path={`${path}/proofing`}>
                            <SelectProduct type="proof" title="Select your proofing option:" />
                        </Route>
                        <Route path={`${path}/*`}>
                            Not found
                        </Route>
                    </Switch>
            }
        </Fragment>
    );
}