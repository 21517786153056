import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ModalConstatns from 'utils/ModalConstants';

export default function AppView({ children }) {
    const location = useLocation();
    useEffect(() => {
        document.getElementById(ModalConstatns.BackdropElName())?.remove();
    }, [location]);

    return (
        <div>{children}</div>
    )
}
