import React, { useEffect } from 'react';

import styles from "./Modal.module.css";
import ModalConstatns from 'utils/ModalConstants';

export default function Modal({ isOpen, verticalAlignCenter = true, children }) {
    useEffect(() => {
        const elementId = ModalConstatns.BackdropElName();
        const element = document.getElementById(elementId);
        if (isOpen) {
            const backgroundEl = document.createElement("div");
            backgroundEl.classList.add("ModalBackdrop");
            backgroundEl.id = elementId;
            document.body.appendChild(backgroundEl)
        } else {
            element?.remove();
        }
        return () => { element?.remove() };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={styles.Modal}>
            <div className={`${styles.Modal_Dialog} ${verticalAlignCenter ? styles.Modal_DialogCenter : ""}`}>
                <div className={styles.Modal_Content}>
                    {children}
                </div>
            </div>
        </div>
    )
}
