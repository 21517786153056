import React from 'react'

export default function AnnouncementMessage({ visible }) {
    const blockStyle = {
        width: "100%",
        textAlign: "center",
        lineHeight: "1",
        marginTop: ".275rem",
        paddingBottom: ".275rem"
    };

    if (visible) {
        return (
            <div style={blockStyle}>
                <div style={{ fontSize: "1.05rem", fontWeight: "600", marginBottom: ".275rem" }}>
                    Due to inclement weather, our studios will be closed September 28th, September 29th, and September 30th.
                    <br /> We will reopen on Saturday, October 1st, weather permitting.
                </div>
                <div style={{ fontSize: ".875rem" }}>

                </div>
            </div>
        )
    } else {
        return null;
    }

}
