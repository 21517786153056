import React from 'react';

import styles from './CheckboxCard.module.css';

export default function CheckboxCard({ checked, className, children, ...props }) {
    return (
        <div className={`${className ? className : ''} ${styles.CheckCard} ${checked ? styles.IsSelected : ''}`} {...props}>
            <input type="checkbox" checked={checked} onChange={() => { }} />
            <div className={styles.CheckTitle}>
                {children}
            </div>
        </div>
    )
}
