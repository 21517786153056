import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import FindAccessCode from 'components/Routes/Schedule/FindAccessCode';
import SelectAccessCode from 'components/Routes/Schedule/SelectAccessCode';
import AccessCodeRoute from './AccessCodeRoute';

export default function ScheduleRoute() {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <SelectAccessCode />
            </Route>
            <Route exact path={`/schedule/findaccesscode`}>
                <FindAccessCode />
            </Route>
            <Route path={`/schedule/:accesscode`}>
                <AccessCodeRoute />
            </Route>
            <Route path={`${path}/*`}>
                Invalid route.
            </Route>
        </Switch>);
}
