export default class ProductConstants {
    static getPackagePlanNumber(type) {
        let result;
        switch (type) {
            case "main":
                result = "SESSIONS"
                break;
            default:
                result = "SR-PRF-LEVELS"
                break;
        }
        return result;
    }
}