import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import styles from './CustomLink.module.css';

export default function CustomLink({ className, exact = true, large = false, external, ...props }) {
    let match = useRouteMatch({
        path: props.to,
        exact: exact
    });

    return (
        <Link
            className={`${styles.CustomLink} ${className || ''} ${match ? styles.Active : ''} ${large ? styles.Large : ''}`}
            onClick={() => { if (external) window.location.replace(props.to) }} {...props}>
        </Link>
    );
}