import React from 'react';
import Button from 'components/UI/Button';

import styles from "./NavActions.module.css";

export default function NavActions({ children, onGoBack }) {
    if (!onGoBack && !children) return null;

    return (
        <div className={styles.NavActions}>
            {
                onGoBack
                &&
                <Button className={styles.GoBackBtn} onClick={onGoBack} blue>Go back</Button>
            }
            <div className={styles.Children}>
                {children}
            </div>
        </div>
    )
}
