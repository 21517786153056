import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout, signin } from 'store/auth';
import { setAccessCode, clearCartState } from 'store/cart';

import styles from "./Signin.module.css";

export default function Signin() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isAuth, isAuthLoading, authExpiredMessage } = useSelector(state => state.auth)
    const [isLoading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [message, setMessage] = useState('');
    const [forgotPasswordLink, setForgotPasswordLink] = useState(false);

    const setEmail = (ev) => {
        setCredentials({ ...credentials, email: ev.target.value.trim() });
    };

    const setPassword = (ev) => {
        setCredentials({ ...credentials, password: ev.target.value.trim() });
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        if (!ev.target.reportValidity()) {
            return;
        }

        setLoading(true);
        const { payload } = await dispatch(signin(credentials));
        setLoading(false);

        if (!payload.isSuccess) {
            setLoading(false);
            setMessage('Invalid credentials.');
            setForgotPasswordLink(true);
            ev.target.timoutId && clearTimeout(ev.target.timoutId);
            ev.target.timoutId = setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    };

    useEffect(() => {
        if (isAuth) {
            if (history.length <= 2) {
                history.push("/schedule");
            } else {
                history.goBack();
            }
            return;
        }
    }, [history, isAuth]);

    useEffect(() => {
        if (!isAuth && !isAuthLoading && !isLoading) {
            dispatch(setAccessCode(""));
            dispatch(clearCartState());
            dispatch(logout());
        }
    }, [dispatch, isAuth, isAuthLoading, isLoading])

    return (
        <div className={styles.SigninScreen}>
            <div className={styles.SigninContent}>
                {
                    authExpiredMessage
                        ?
                        <div style={{ fontSize: "1.275rem", marginBottom: ".5rem", color: "red" }}>
                            {authExpiredMessage}
                        </div>
                        :
                        <div className={styles.WelcomeText}>Welcome back!</div>
                }
                <div style={{ fontSize: "1.275rem", marginBottom: ".5rem" }}>
                    Please sign in:
                </div>
                <form className={styles.SignInForm} onSubmit={handleSubmit}>
                    <Input type='email' placeholder='Email *' value={credentials.email} onInput={setEmail} validateOnInput={false} required />
                    <Input type='password' placeholder='Password *' value={credentials.password} onInput={setPassword} validateOnInput={false} required />
                    {
                        message &&
                        <div className={styles.Validation}>
                            {message}
                        </div>
                    }
                    <Button type='submit' isLoading={isLoading}>Sign in</Button>
                </form>
                <div className={styles.CreateAccount}>
                    Don't have an account?
                    <Link replace={true} to="/register">Create one</Link>
                </div>
                {
                    forgotPasswordLink
                    &&
                    <div>
                        <a href='https://new.leonards.com/ForgotPassword' target='_blank' rel="noreferrer">forgot my password</a>
                    </div>
                }
            </div>
        </div>);
}