import { configureStore } from "@reduxjs/toolkit";
import auth from "store/auth";
import cart from "store/cart";
import notification from "store/notification"

const Store = configureStore({
    reducer: {
        auth: auth,
        cart: cart,
        notification: notification
    },
    devTools: process.env.REACT_APP_DEV_TOOLS
});

export default Store;