import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import NavActions from 'components/UI/NavActions';
import Title from 'components/UI/Title';
import CheckboxCard from 'components/UI/ChekboxCard';
import SubTitle from 'components/UI/SubTitle';
import Loading from 'components/UI/Loading';
import FindTime from 'components/Routes/Cart/FindTime';

import styles from './Retake.module.css';

export default function Retake() {
    const history = useHistory();
    const { accesscode } = useParams();
    const [retakeOption, setRetakeOption] = useState("");
    const [schedule, setSchedule] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSchedule = async () => {
            setLoading(true);
            try {
                const { data } = await SeniorsScheduleService.loadSchedule(accesscode);
                if (data.schedule && Object.keys(data.schedule).length > 0) {
                    setSchedule(data.schedule);
                } else {
                    history.goBack();
                }
                setLoading(false);
            } catch {
                setLoading(false);
            }
        }
        fetchSchedule();
    }, [history, accesscode]);

    if (isLoading) return <Loading small />

    return (
        <div className={styles.Retake}>
            <NavActions onGoBack={() => { history.replace("/schedule/" + accesscode) }}></NavActions>
            <Title smallMargin>
                What do you want to retake?
                <SubTitle textCenter>Click on option to select:</SubTitle>
            </Title>
            <div className={styles.RetakeOptions}>
                <CheckboxCard
                    checked={retakeOption === "session"}
                    onClick={() => { retakeOption && retakeOption === "session" ? setRetakeOption("") : setRetakeOption("session") }} >
                    Session
                </CheckboxCard>
                <CheckboxCard
                    checked={retakeOption === "yearbook"}
                    onClick={() => { retakeOption && retakeOption === "yearbook" ? setRetakeOption("") : setRetakeOption("yearbook") }} >
                    Yearbook only
                </CheckboxCard>
            </div>
            {
                retakeOption && retakeOption === "yearbook"
                &&
                <FindTime
                    accessCode={schedule.accessCode}
                    mainProductId={"0000004730"}
                    count={schedule.count}
                    contentOnly={true}
                    scheduleType="retake" />
            }
            {
                retakeOption && retakeOption === "session"
                &&
                <FindTime
                    accessCode={schedule.accessCode}
                    mainProductId={schedule.mainProductId}
                    count={schedule.count}
                    contentOnly={true}
                    scheduleType="retake" />
            }
        </div>
    )
}
