import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAppointmentInfo } from 'store/cart';
import Loading from 'components/UI/Loading';
import SeniorsScheduleService from 'services/SeniorsScheduleService';

import styles from './SelectTime.module.css';

export default function SelectTime({ date, selectedStudio, accessCode, mainProductId, scheduleType = "new" }) {
    const componentRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const [areSlotsLoading, setSlotsLoading] = useState(false);
    const [slots, setSlots] = useState([]);
    const [loadingSlot, setLoadingSlot] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorTimeoutId, setErrorTimeoutId] = useState("");

    const fetchSlots = useCallback(async () => {
        if (date && !loadingSlot) {
            const { data } = await SeniorsScheduleService.getStudioTimeSlots(accessCode, selectedStudio.contractNumber, mainProductId, date);
            setSlots(data.slots);
            setSlotsLoading(false);
            return;
        }
    }, [accessCode, date, selectedStudio, mainProductId, loadingSlot]);

    useEffect(() => {
        componentRef.current?.scrollIntoView({ behavior: "smooth" });
    }, []);

    useEffect(() => {
        setSlots([]);
        setSlotsLoading(true);
        fetchSlots();
        const intervalId = setInterval(() => {
            fetchSlots();
        }, 5000);
        return function cleanup() {
            clearInterval(intervalId);
        };
    }, [fetchSlots])

    const onSlotSelected = async (slot) => {
        if (loadingSlot) return;

        setLoadingSlot(slot);

        try {
            let result;
            if (scheduleType === "new") {
                result = await SeniorsScheduleService.holdTimeSlot(accessCode, selectedStudio.contractNumber, mainProductId, date, slot);
            } else {
                result = await SeniorsScheduleService.rescheduleTime(accessCode, selectedStudio.contractNumber, mainProductId, date, slot);
            }
            const { data } = result;

            setLoadingSlot('');
            if (data.status !== 200) {
                setErrorMessage(data.message);
                clearTimeout(errorTimeoutId);
                setErrorTimeoutId(setTimeout(() => {
                    setErrorMessage('');
                }, 3000));
                fetchSlots();
                return;
            }
            history.replace(scheduleType === "new" ? "/cart" : "/schedule/" + accessCode);
            dispatch(setAppointmentInfo({ selectedStudio, date: date, slot }));
        } catch {
            fetchSlots();
            setLoadingSlot('');
        }
    };

    return (
        <div className={styles.SelectTime} ref={componentRef}>
            {
                errorMessage
                &&
                <div className={styles.ErrorMessage}>
                    {errorMessage}
                </div>
            }
            {
                areSlotsLoading ?
                    <Loading />
                    :
                    <div className={styles.Slots}>
                        {!date && "Please select date."}
                        {(slots.length === 0 && date) && "No available time found."}
                        {
                            slots.map((slot) => (
                                <div key={slot.replaceAll(" ", "")}
                                    className={`${styles.Slot}`}
                                    onClick={() => onSlotSelected(slot)}>
                                    {slot}
                                    {loadingSlot === slot && <Loading small />}
                                </div>)
                            )
                        }
                    </div>
            }
        </div>
    )
}
