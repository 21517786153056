import ProductConstants from "utils/ProductConstants";

export default class ProductHelper {
    static findProduct(products, type) {
        return products.filter(product => product.packagePlanNumber?.toUpperCase() === ProductConstants.getPackagePlanNumber(type))[0];
    };
    static isYearbookPlusProduct(product) {
        return product.productCode === "0000004270" || product.packageNumber.toUpperCase().includes("PLUS");
    };
    static isOnlinePrfProduct(product) {
        return product.productCode === "0000004226" || product.packageNumber.toUpperCase().includes("ONLINE PRF");
    };
    static isBookPrfProduct(product) {
        return product.productCode === "0000004227" || product.packageNumber.toUpperCase().includes("PRF BOOK");
    };
    static findOnlinePrfProduct(products) {
        return products.filter(product => this.isOnlinePrfProduct(product))[0];
    };
    static findBookPrfProduct(products) {
        return products.filter(product => this.isBookPrfProduct(product))[0];
    };
}