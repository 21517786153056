import CustomLink from 'components/UI/CustomLink';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from 'store/auth';
import { setAccessCode, clearCartState } from 'store/cart';

import styles from "./Header.module.css";

export default function Header() {
    const dispatch = useDispatch();
    const { isAuth } = useSelector(state => state.auth);
    const { products } = useSelector(state => state.cart);

    const handleLogout = async () => {
        dispatch(setAccessCode(""));
        dispatch(clearCartState());
        await dispatch(logout());
    };

    return (
        <header className={`${styles.Header} Container`}>
            <div className={styles.Content}>
                <Link to="/schedule" className={styles.Logo}>
                    <div className={styles.LeonardsImgLogo}>
                        <img src='/logo.png' alt='Logo' />
                    </div>
                </Link>
                <nav className={styles.Navigation}>
                    <CustomLink to='/schedule' exact={false}>schedule</CustomLink>
                    {isAuth ?
                        <Fragment>
                            <CustomLink to="#" onClick={handleLogout}>logout</CustomLink>
                            <CustomLink to='/cart' exact={false}>cart({products ? products.length : 0})</CustomLink>
                        </Fragment>
                        :
                        <div className={styles.Navigation}>
                            <CustomLink to='/signin'>sign in</CustomLink>
                            <CustomLink to='/register'>register</CustomLink>
                        </div>
                    }
                </nav>
            </div>
        </header>);
}
