import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "services/AuthService";
import { TOKEN_NAME } from "http";

export const rememberMeKey = "ss-remember-me";

export const signin = createAsyncThunk(
    'user/signinstatus',
    async (payload, thunkAPI) => {
        let isSuccess = false;
        const { email, password } = payload;

        try {
            const response = await AuthService.signin(email, password);

            if (response.data.status === 200) {
                localStorage.setItem(TOKEN_NAME, response.data.accessToken);
                isSuccess = true;
            }
        } catch {
            // Error handling and logging
        }

        return { isSuccess }
    }
);

export const signinWithGuid = createAsyncThunk(
    'user/signinWithGuidStatus',
    async (payload, thunkAPI) => {
        let isSuccess = false;
        const guid = payload;

        try {
            const response = await AuthService.signinWithGuid(guid);

            if (response.data.status === 200) {
                localStorage.setItem(TOKEN_NAME, response.data.accessToken);
                isSuccess = true;
            }
        } catch {
            // Error handling and logging
        }

        return { isSuccess }
    }
);

export const register = createAsyncThunk(
    'user/registerstatus',
    async (payload, thunkAPI) => {
        let isSuccess = false;
        let message = '';
        const { email, password, firstName, lastName } = payload;

        try {
            const response = await AuthService.register(email, password, firstName, lastName);

            if (response.data.status === 200) {
                localStorage.setItem(TOKEN_NAME, response.data.accessToken);
                isSuccess = true;
            }
            message = response.data.message || message;

        } catch {
            // Error handling and logging
        }

        return { isSuccess, message }
    }
);

export const logout = createAsyncThunk(
    'user/logoutstatus',
    async () => {
        let isSuccess = false;

        try {
            const response = await AuthService.logout();
            if (response.data.status === 200) {
                localStorage.removeItem(TOKEN_NAME);
                isSuccess = true;
            }
        } catch {
            // Error handling and logging
        }

        return { isSuccess };
    }
);

export const refresh = createAsyncThunk(
    'user/refreshstatus',
    async (payload, thunkAPI) => {
        let isSuccess = false;
        try {
            const response = await AuthService.refresh();

            if (response.data.status === 200) {
                localStorage.setItem(TOKEN_NAME, response.data.accessToken);
                isSuccess = true;
            } else {
                localStorage.removeItem(rememberMeKey);
            }
        } catch {
            localStorage.removeItem(TOKEN_NAME);
            localStorage.removeItem(rememberMeKey);
            // Error handling and logging
        }

        return { isSuccess };
    }
);

export const auth = createSlice({
    name: 'auth',
    initialState: {
        isAuth: false,
        isAuthLoading: true,
        authExpiredMessage: ""
    },
    reducers: {
        setAuthLoading(state, action) {
            if (typeof action.payload === "boolean") {
                state.isAuthLoading = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signin.fulfilled, (state, action) => {
            const { isSuccess } = action.payload;
            state.isAuth = isSuccess;
            if (isSuccess) {
                localStorage.setItem(rememberMeKey, true);
                state.authExpiredMessage = ""
            }
        });

        builder.addCase(signinWithGuid.fulfilled, (state, action) => {
            state.isAuthLoading = false;
            const { isSuccess } = action.payload;
            state.isAuth = isSuccess;
            localStorage.setItem(rememberMeKey, true);
        });

        builder.addCase(signinWithGuid.rejected, (state, action) => {
            state.isAuth = false;
            state.isAuthLoading = false;
            localStorage.removeItem(rememberMeKey);
        });

        builder.addCase(signinWithGuid.pending, (state, action) => {
            state.isAuthLoading = true;
        });

        builder.addCase(register.fulfilled, (state, action) => {
            const { isSuccess } = action.payload;
            state.isAuth = isSuccess;
            localStorage.setItem(rememberMeKey, true);
        });

        builder.addCase(logout.pending, (state, action) => {
            state.isAuth = false;
            localStorage.removeItem(rememberMeKey);
        });

        builder.addCase(refresh.rejected, (state, action) => {
            state.isAuth = false;
            state.isAuthLoading = false;
            state.authExpiredMessage = "Your session has expired.";
            localStorage.removeItem(rememberMeKey);
        });

        builder.addCase(refresh.fulfilled, (state, action) => {
            const { isSuccess } = action.payload;
            state.isAuth = isSuccess;
            if (!isSuccess) state.authExpiredMessage = "Your session has expired.";
        });
    }
});

export const { setAuthLoading } = auth.actions;

export default auth.reducer;