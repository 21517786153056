import api from "http/index";

class ConsentService {
    async getByName(name) {
        let data;
        try {
            data = (await api.get(`consents?name=${name}`)).data;
        } catch (ex) {
            return null;
        }
        return data.consents.length > 0 ? data.consents[0] : null;
    }
}

export default new ConsentService();