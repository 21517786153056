import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AppView from "components/AppView";
import Signin from "components/Routes/Signin";
import ProtectedRoute from "components/ProtectedRoute";
import Header from "components/UI/Header";
import Footer from "components/UI/Footer";
import ScheduleRoute from "components/Routes/Schedule";
import Register from "components/Routes/Register";
import GuidAuth from "components/Routes/GuidAuth";
import Cart from "components/Routes/Cart";
import Notifications from "components/Notifications";
import Reschedule from "components/Routes/Reschedule";
import Retake from "components/Routes/Retake";

import AnnouncementMessage from "components/UI/AnnouncementMessage";
import ProofBookUpsellModal from "components/UISchedule/ProofBookUpsellModal";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <AppView>
        <div className="App">
          <ProofBookUpsellModal />
          <AnnouncementMessage visible={false} />
          <Header />
          <div className="Content Container">
            <Switch>
              <Route exact path="/">
                <Redirect to="/schedule" />
              </Route>
              <Route exact path="/signin">
                <Signin />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <ProtectedRoute path="/cart">
                <Cart />
              </ProtectedRoute>
              <ProtectedRoute path="/retake/:accesscode">
                <Retake />
              </ProtectedRoute>
              <ProtectedRoute path="/makeup/:accesscode">
                <Reschedule />
              </ProtectedRoute>
              <ProtectedRoute path="/reschedule/:accesscode">
                <Reschedule />
              </ProtectedRoute>
              <ProtectedRoute path="/schedule">
                <ScheduleRoute />
              </ProtectedRoute>
              <Route path="/guid/:guid" exact>
                <GuidAuth />
              </Route>
              <Route path="*">
                Not found
              </Route>
            </Switch>
          </div>
          <Footer />
          <Notifications />
        </div>
      </AppView>
    </BrowserRouter>
  );
}

export default App;