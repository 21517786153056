import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import Title from 'components/UI/Title';
import SubTitle from 'components/UI/SubTitle';
import NavActions from 'components/UI/NavActions';
import Button from 'components/UI/Button';
import CustomLink from 'components/UI/CustomLink';
import AppointmentInfo from 'components/UISchedule/AppointmentInfo';
import ProductCard from 'components/UISchedule/ProductCard';

import styles from './ViewSchedule.module.css';

export default function ViewSchedule({ schedule }) {
    const history = useHistory();
    const { accesscode } = useParams();
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [noRefundCancelModalVisible, setNoRefundCancelModalVisible] = useState(false);
    const [isCancelLoading, setCancelLoading] = useState(false);
    const [cancelError, setCancelError] = useState("");
    const [hasCanceled, setCenceled] = useState(false);

    const onCancel = async () => {
        try {
            setCancelLoading(true);
            var { data } = await SeniorsScheduleService.cancel(accesscode);
            setCancelLoading(false);

            if (data.status !== 200) {
                setCancelError(data.message ? data.message : "");
            } else {
                setCancelError("");
                setCancelModalVisible(false);
                setCenceled(true);
            }
        } catch {
            setCancelLoading(false);
        }
    };

    const onNoRefundCancel = async () => {
        try {
            setCancelLoading(true);
            var { data } = await SeniorsScheduleService.cancelWithoutRefund(accesscode);
            setCancelLoading(false);

            if (data.status !== 200) {
                setCancelError(data.message ? data.message : "");
            } else {
                setCancelError("");
                setCancelModalVisible(false);
                setCenceled(true);
            }
        } catch {
            setCancelLoading(false);
        }
    }

    if (hasCanceled) {
        return (<div className={styles.Canceled}>
            <div className={styles.Canceled_Text}>
                Your schedule for {accesscode} has been canceled.
            </div>
            <CustomLink to="/schedule">create schedule</CustomLink>
        </div>);
    }

    return (
        <div className={styles.ViewSchedule}>
            <NavActions onGoBack={() => { history.replace("/schedule") }}>
                {
                    new Date(schedule.appointmentInfo.date) < new Date() || schedule.hasRetake
                        ?
                        <Button onClick={() => history.push("/retake/" + accesscode)} blue>
                            Retake
                        </Button>
                        :
                        null
                }
                {
                    new Date(schedule.appointmentInfo.date) > new Date() && !schedule.hasRetake
                    &&
                    <Button onClick={() => history.push("/reschedule/" + accesscode)} blue>
                        Reschedule
                    </Button>
                }
                {
                    new Date(schedule.appointmentInfo.date) < new Date() && !schedule.hasRetake
                    &&
                    <Button onClick={() => history.push("/makeup/" + accesscode)} blue>
                        Makeup
                    </Button>
                }
                {
                    schedule.allowNoRefundCancel ?
                        <Button onClick={() => { setNoRefundCancelModalVisible(true); setCancelError(""); }} danger>
                            Cancel w/o refund
                        </Button>
                        :
                        null
                }
                {
                    (new Date(schedule.appointmentInfo.date) > new Date() && !schedule.hasRetake) || schedule.allowCancel ?
                        <Button onClick={() => { setCancelModalVisible(true); setCancelError(""); }} danger>
                            Cancel
                        </Button>
                        :
                        null
                }
            </NavActions>
            <Title>
                {`Thank you for scheduling appointment for ${schedule.studentName}`}
                <SubTitle textCenter >See your schedule details below:</SubTitle>
            </Title>
            <SubTitle>Appointment information:</SubTitle>
            <AppointmentInfo info={schedule.appointmentInfo} />
            <SubTitle textCenter >Student information:</SubTitle>
            <div className={styles.StudentInformation}>
                <div>
                    <SubTitle textCenter>Name:</SubTitle>
                    <div className={styles.StudentName}>
                        {schedule.studentName}
                    </div>
                </div>
                <div>
                    <SubTitle textCenter>School:</SubTitle>
                    <div className={styles.StudentSchool}>
                        {schedule.studentSchool}
                    </div>
                </div>
                <div>
                    <SubTitle textCenter>Access code:</SubTitle>
                    <div className={styles.AccessCode}>
                        {accesscode}
                    </div>
                </div>
            </div>
            <div className={styles.Products}>
                <div className={styles.MainProduct}>
                    <SubTitle textCenter>Selected session:</SubTitle>
                    <ProductCard
                        productId={schedule.mainProductId}
                        accessCode={accesscode}
                        showSelected={false}
                        horizontal={true}
                        allowAction={false}
                        price={schedule.cartItemSession.price}
                        futures={schedule.cartItemSession.contents} />
                </div>
                <div className={styles.ProofProduct}>
                    <SubTitle textCenter>Selected proofing option:</SubTitle>
                    <ProductCard
                        productId={schedule.proofProductId}
                        accessCode={accesscode}
                        showSelected={false}
                        horizontal={true}
                        allowAction={false}
                        price={schedule.cartItemProof.price}
                        futures={schedule.cartItemProof.contents}
                        type="proof" />
                </div>
            </div>
            {
                cancelModalVisible &&
                <div className={styles.CancelModal} onClick={() => setCancelModalVisible(false)}>
                    <div className={styles.CancelModal_Content} onClick={(ev) => ev.stopPropagation()}>
                        Are you sure you want to cancel schedule for {accesscode}?
                        {cancelError &&
                            <div className={styles.CancelModal_Error}>
                                {cancelError}
                            </div>
                        }
                        <div className={styles.CancelModal_Actions}>
                            <Button isLoading={isCancelLoading} onClick={onCancel} danger>
                                Yes
                            </Button>
                            <Button onClick={() => setCancelModalVisible(false)} blue>
                                No
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                noRefundCancelModalVisible &&
                <div className={styles.CancelModal} onClick={() => setNoRefundCancelModalVisible(false)}>
                    <div className={styles.CancelModal_Content} onClick={(ev) => ev.stopPropagation()}>
                        Are you sure you want to cancel schedule for {accesscode} WITHOUT REFUND?
                        {cancelError &&
                            <div className={styles.CancelModal_Error}>
                                {cancelError}
                            </div>
                        }
                        <div className={styles.CancelModal_Actions}>
                            <Button isLoading={isCancelLoading} onClick={onNoRefundCancel} danger>
                                Yes, I want to cancel WITHOUT refund
                            </Button>
                            <Button onClick={() => setNoRefundCancelModalVisible(false)} blue>
                                No
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
