import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCartState, setAccessCode, LS_ACCESSCODE_KEY } from 'store/cart';
import { addNotification } from 'store/notification';
import SeniorsScheduleService from 'services/SeniorsScheduleService';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';
import AddressForm from 'components/UI/AddressForm';
import NavActions from 'components/UI/NavActions';
import CustomLink from 'components/UI/CustomLink';
import AccessCodeError from 'components/UISchedule/AccessCodeError';

import styles from './UpdateAccessCodeInfo.module.css';
import ConsentService from 'services/ConsentService';

export default function UpdateAccessCodeInfo() {
    const history = useHistory();
    const dispatch = useDispatch()
    const [isAccessCodeInfoLoading, setInfoLoading] = useState(true);
    const [isInfoSaving, setInfoSaving] = useState(false);
    const [isUserEmployee, setUserEmployee] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const initialAccessCodeInfo = { firstName: '', lastName: '', email: '', phone: '', address1: '', address2: '', city: '', zipCode: '', state: '', schoolName: '', isConsent: true, consentId: '', consentText: "" };
    const [accessCodeInfo, setAccessCodeInfo] = useState({ ...initialAccessCodeInfo });
    const [accessCodeNotFound, setAccessCodeNotFound] = useState(false);
    const { accesscode } = useParams();

    useEffect(() => {
        const fetchAccessCodeInfo = async (code) => {
            const { data } = await SeniorsScheduleService.getAccessCodeInfo(code);
            const consent = await ConsentService.getByName("seniors_schedule_images_use");
            setInfoLoading(false);
            if (data.status === 200) {
                data.accessCodeInfo.isConsent = true;
                data.accessCodeInfo.consentId = consent?.id;
                data.accessCodeInfo.consentText = consent?.text;
                setAccessCodeInfo({ ...data.accessCodeInfo });
                setUserEmployee(data.isEmployee);
                if (accesscode !== localStorage.getItem(LS_ACCESSCODE_KEY)) {
                    dispatch(clearCartState());
                }
            } else {
                const notification = { type: "info", content: data.message };
                setAccessCodeNotFound(true);
                dispatch(addNotification(notification));
                dispatch(setAccessCode(""));
            }
        };
        fetchAccessCodeInfo(accesscode);
    }, [accesscode, dispatch]);

    const saveAccessCodeInfo = async (ev) => {
        ev.preventDefault();
        if (isUserEmployee) {
            history.push(`/schedule/${accesscode}/sessions`);
            dispatch(setAccessCode(accesscode));
            return;
        }

        setInfoSaving(true);
        const res = await SeniorsScheduleService.updateAccessCodeInfo(accesscode, accessCodeInfo);
        setInfoSaving(false);
        if (res.data.status !== 200) {
            setErrorMessage(res.data.message);
            clearTimeout(ev.target.timeoutId);
            ev.target.timeoutId = setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        } else {
            history.push(`/schedule/${accesscode}/sessions`);
            dispatch(setAccessCode(accesscode));
            return;
        }
    };


    return (
        <div className={styles.ConfirmAccessCode}>
            <NavActions onGoBack={() => { history.replace("/schedule") }} />
            {accessCodeNotFound ?
                <AccessCodeError visible={accessCodeNotFound} accessCode={accesscode} />
                :
                <Fragment>
                    {(isAccessCodeInfoLoading) ?
                        <Loading />
                        :
                        <Fragment>
                            <Title>Please confirm student information:</Title>
                            <AddressForm
                                initialState={accessCodeInfo}
                                onInitialStateUpdate={setAccessCodeInfo}
                                allowNameChange={false}
                                errorMessage={errorMessage}
                                loading={isInfoSaving}
                                onSubmit={saveAccessCodeInfo}
                                isUserEmployee={isUserEmployee}
                                onSubmitText={isUserEmployee ? "Continue" : "Save and continue"} >

                                {
                                    isUserEmployee || !accessCodeInfo.consentId ?
                                        null
                                        :
                                        <div className={styles.Consent}>
                                            <input
                                                type='checkbox'
                                                id='consent-checkbox'
                                                defaultChecked={accessCodeInfo.isConsent}
                                                onChange={(ev) => { setAccessCodeInfo({ ...accessCodeInfo, isConsent: ev.target.checked }) }}
                                                disabled={isUserEmployee}
                                            />
                                            <label className={styles.Consent_Text} htmlFor="consent-checkbox">
                                                {accessCodeInfo.consentText}
                                            </label>
                                        </div>
                                }
                            </AddressForm>
                            <div className={styles.InvalidAccessCode}>
                                Not you? <CustomLink to="/schedule/findaccesscode">find access code</CustomLink>
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }
        </div>);
}
