import React, { Fragment, useLayoutEffect, useState } from 'react';
import Loading from 'components/UI/Loading';
import ProductService from 'services/ProductService';

import styles from "./ProductImages.module.css";

export default function ProductImages({ productId, square = false, ...props }) {
    const imagesFolder = `${process.env.REACT_APP_URL}/images`;
    const [isImagesLoading, setImagesLoading] = useState(true);
    const [images, setImages] = useState(props.images || []);
    const [mainImage, setMainImage] = useState(props.images ? props.images[0] : null);

    useLayoutEffect(() => {
        if (props.images) {
            setImagesLoading(false);
            return;
        }
        const fetchImages = async () => {
            setImagesLoading(true);
            const { data } = await ProductService.getProductImages(productId);
            setImagesLoading(false);
            if (data.status === 200) {
                setMainImage(data.count > 0 ? data.images[0] : null);
                setImages(data.images);
            }
        };
        fetchImages();
    }, [productId, props.images]);

    const setSelectedImage = (id) => {
        setImages(
            [
                ...images.map(image => { return { ...image, isSelected: image.id === id }; })
            ]
        );
        setMainImage(images.filter(image => image.id === id)[0]);
    };

    const handleImageClick = (ev) => {
        setSelectedImage(ev.target.id);
    };

    return (
        <div className={styles.Images}>
            {
                isImagesLoading && <Loading small />
            }
            {
                images.length === 0 && !isImagesLoading
                    ?
                    "No images found."
                    :
                    <Fragment>
                        <div className={`${square ? styles.Square : styles.MainImage}`}>
                            {mainImage && <img src={`${imagesFolder}/${mainImage.fileName}`} alt='Example' />}
                        </div>
                        {
                            !square ?
                                <div className={styles.Previews}>
                                    {
                                        images.map((image) => {
                                            return (
                                                <img key={image.id} src={`${imagesFolder}/${image.fileName}`} id={image.id} className={image.id === mainImage.id ? styles.IsSelected : ""} alt='Example' onClick={handleImageClick} />
                                            );
                                        })
                                    }
                                </div>
                                :
                                null
                        }
                    </Fragment>
            }
        </div>
    );
};