import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeProduct, setCouponInfo } from "store/cart";
import { addNotification, removeNotification } from "store/notification";
import { useHistory } from "react-router-dom";
import CartService from "services/CartService";
import CustomLink from "components/UI/CustomLink";
import Title from "components/UI/Title";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import NavActions from "components/UI/NavActions";
import CartTotals from "components/Routes/Cart/CartTotals";
import SubTitle from "components/UI/SubTitle";
import ProductCard from "components/UISchedule/ProductCard";
import AppointmentInfo from "components/UISchedule/AppointmentInfo";
import AppointmentHoldTimer from "components/UISchedule/AppointmentHoldTimer";

import ProductHelper from "helpers/ProductHelper";

import styles from "./ViewCart.module.css";

export default function ViewCart() {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const [coupon, setCoupon] = useState("");
    const [couponError, setCouponError] = useState("");
    const [isCouponLoading, setCouponLoading] = useState(false);

    const { products, accessCode, couponInfo, appointmentInfo } = useSelector(store => store.cart);
    const mainProduct = ProductHelper.findProduct(products, "main");
    const proofProduct = ProductHelper.findProduct(products, "proof");

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, []);

    const handleRemoveFromCart = async (product, setLoading, type) => {
        setLoading(true);
        try {
            const { data } = await CartService.removeProductFromCart(product.id);
            if (data.status !== 200) {
                return;
            }
            setLoading(false);
            dispatch(removeProduct({ type }));
        } catch {
            setLoading(false);
        }
    };

    const updateCouponCode = async () => {
        var appliedCouponCode = couponInfo ? couponInfo?.code : "";
        if (!coupon) {
            setCouponError("Coupon may not be empty.");
            setTimeout(() => {
                setCouponError("");
            }, 3000);
            return;
        }
        if (coupon === appliedCouponCode) {
            setCouponError(`Coupon ${appliedCouponCode} has been already applied.`);
            setTimeout(() => {
                setCouponError("");
            }, 3000);
            return;
        }
        try {
            setCouponLoading(true);
            const { data } = await CartService.applyCoupon(accessCode, coupon || appliedCouponCode);
            setCouponLoading(false);
            if (data.status === 200) {
                setCouponError("");
                dispatch(setCouponInfo(data.couponInfo));
                const notification = {
                    type: "info",
                    content: `Coupon code ${coupon || appliedCouponCode} has been applied.`
                };
                if (coupon) {
                    dispatch(addNotification(notification));
                    setTimeout(() => {
                        dispatch(removeNotification(notification));
                    }, 3000);
                }
            } else {
                setCouponError(data.message);
                setTimeout(() => {
                    setCouponError("");
                }, 3000);
            }
        } catch {
            dispatch(setCouponInfo({}));
        }
        setCoupon("");
    };

    return (
        <div className={styles.Cart} ref={ref}>
            <NavActions>
                {(mainProduct && proofProduct && appointmentInfo && Object.keys(appointmentInfo).length > 0) && <Button onClick={() => history.push("/cart/pay")} blue>Checkout</Button>}
            </NavActions>
            <Title>Your cart:</Title>
            <div className={styles.CartInfo}>
                {
                    (mainProduct && proofProduct) &&
                    <Fragment>
                        <SubTitle>Appointment information: {(appointmentInfo && Object.keys(appointmentInfo).length > 0) && <div>Valid for:<AppointmentHoldTimer /></div>}</SubTitle>
                        <AppointmentInfo info={appointmentInfo}>
                            <CustomLink to="/cart/find-time">change time</CustomLink>
                        </AppointmentInfo>
                    </Fragment>
                }
                {
                    mainProduct || proofProduct ?
                        <Fragment>
                            <SubTitle>Selected products:</SubTitle>
                            <div className={styles.Products}>
                                <div className={styles.MainProduct}>
                                    {
                                        !mainProduct &&
                                        <Fragment>
                                            {"Session is not selected."}
                                            <CustomLink to={`schedule/${accessCode}/sessions`}>select session</CustomLink>
                                        </Fragment>
                                    }
                                    {
                                        mainProduct &&
                                        <ProductCard
                                            product={mainProduct}
                                            accessCode={accessCode}
                                            showSelected={false}
                                            horizontal={true}
                                            onRemoveFromCart={handleRemoveFromCart}
                                            isSelected />
                                    }
                                </div>
                                <div className={styles.ProofProduct}>
                                    {
                                        !proofProduct &&
                                        <Fragment>
                                            {"Proofing option is not selected."}
                                            <CustomLink to={`schedule/${accessCode}/proofing`}>select proofing option</CustomLink>
                                        </Fragment>
                                    }
                                    {
                                        proofProduct &&
                                        <ProductCard
                                            product={proofProduct}
                                            accessCode={accessCode}
                                            showSelected={false}
                                            horizontal={true}
                                            onRemoveFromCart={handleRemoveFromCart}
                                            type="proof"
                                            isSelected />
                                    }
                                </div>
                            </div>
                            {mainProduct && proofProduct ?
                                <Fragment>
                                    <div className={styles.ApplyCouponEl}>
                                        <div className={styles.ApplyCouponInputs}>
                                            <Input placeholder="Coupon" value={coupon} onInput={({ target }) => { setCoupon(target.value.toUpperCase()) }} />
                                            <Button onClick={updateCouponCode} isLoading={isCouponLoading}>Apply</Button>
                                        </div>
                                        {
                                            couponError
                                            &&
                                            <div className={styles.CouponError}>
                                                {couponError}
                                            </div>
                                        }
                                    </div>
                                    <CartTotals />
                                </Fragment>
                                :
                                null
                            }
                        </Fragment>
                        :
                        <div className={styles.NoProducts}>
                            Cart is empty.
                            <div className={styles.EmptyCart_Links}>
                                {
                                    accessCode && <CustomLink to={`/schedule/${accessCode}/sessions`}>start ordering</CustomLink>
                                }
                                <CustomLink to={`/schedule`}>{accessCode ? "change access code" : "start ordering"}</CustomLink>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
