import React from 'react';

import styles from "./Footer.module.css";

export default function Footer() {
    return (
        <div className={`${styles.Footer} Conainer`}>
            <div className={styles.SocialIcons}>
                <a href='https://instagram.com/leonards_photo' target='_blank' rel="noreferrer"><img src='/images/icon_instagram.png' alt='Instagram link' /></a>
                <a href='https://facebook.com/leonardsphotography' target='_blank' rel="noreferrer"><img src='/images/icon_facebook.png' alt='Facebook link' /></a>
            </div>
            <div className={styles.Copyright}>
                <span className={styles.CopyrightSymbol}>&copy;</span>
                <span className={styles.CopyrightDate}>{new Date().getFullYear()}</span>
                <span className={styles.CopyrightText}>Leonard's Studio</span>
            </div>
        </div>);
}
