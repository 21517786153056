import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FindTime from 'components/Routes/Cart/FindTime';
import ViewCart from 'components/Routes/Cart/ViewCart';
import PayCart from 'components/Routes/Cart/PayCart';
import ProductHelper from 'helpers/ProductHelper';

export default function Cart() {
    let { path } = useRouteMatch();
    const { accessCode, products } = useSelector(state => state.cart);
    const mainProduct = ProductHelper.findProduct(products, "main");
    return (
        <Switch>
            <Route exact path={path}>
                <ViewCart />
            </Route>
            <Route exact path={`${path}/find-time`}>
                <FindTime accessCode={accessCode} mainProductId={mainProduct ? mainProduct.id : ""} count={products.length} />
            </Route>
            <Route exact path={`${path}/pay`}>
                <PayCart />
            </Route>
            <Route path={`${path}/*`}>
                Not found *
            </Route>
        </Switch>);
}
