import React from 'react';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

import styles from './AddressForm.module.css';

export default function AddressForm({ initialState, onInitialStateUpdate, isUserEmployee = false, allowNameChange = true, errorMessage, loading, onSubmitText, children, buttonLarge = false, ...props }) {
    const { schoolName } = initialState;

    const setFirstName = (ev) => {
        onInitialStateUpdate({ ...initialState, firstName: ev.target.value.trim() });
    };

    const setLastName = (ev) => {
        onInitialStateUpdate({ ...initialState, lastName: ev.target.value.trim() });
    };

    const setEmail = (ev) => {
        onInitialStateUpdate({ ...initialState, email: ev.target.value.trim() });
    };

    const setPhone = (ev) => {
        onInitialStateUpdate({ ...initialState, phone: ev.target.value.trim() });
    };

    const setAddress1 = (ev) => {
        onInitialStateUpdate({ ...initialState, address1: ev.target.value });
    };

    const setAddress2 = (ev) => {
        onInitialStateUpdate({ ...initialState, address2: ev.target.value });
    };

    const setCity = (ev) => {
        onInitialStateUpdate({ ...initialState, city: ev.target.value });
    };

    const setZipCode = (ev) => {
        onInitialStateUpdate({ ...initialState, zipCode: ev.target.value });
    };

    const setState = (ev) => {
        onInitialStateUpdate({ ...initialState, state: ev.target.value.toUpperCase() });
    };

    return (
        <form className={styles.AddressForm} {...props}>
            {
                schoolName ?
                    <Input placeholder='School name'
                        label={`School name:`}
                        value={schoolName}
                        onInput={() => { }}
                        disabled />
                    :
                    null
            }

            <div className={styles.Row}>
                <Input placeholder='First name'
                    label={`First name:${allowNameChange ? " *" : ""}`}
                    value={initialState.firstName}
                    onChange={allowNameChange ? setFirstName : (() => { })}
                    disabled={!allowNameChange}
                    required />
                <Input
                    placeholder='Last name'
                    label={`Last name:${allowNameChange ? " *" : ""}`}
                    value={initialState.lastName}
                    onChange={allowNameChange ? setLastName : (() => { })}
                    disabled={!allowNameChange}
                    required />
            </div>
            <div className={`${styles.Row} ${styles.ColumnSm}`}>
                <Input
                    type='email'
                    placeholder='Email'
                    label={`Email:${isUserEmployee ? '' : ' *'}`}
                    value={initialState.email}
                    onInput={setEmail}
                    required={!isUserEmployee}
                    disabled={isUserEmployee} />
                <Input
                    placeholder='Phone'
                    label={`Phone:${isUserEmployee ? '' : ' *'}`}
                    maxLength='10'
                    value={initialState.phone}
                    onInput={setPhone}
                    required={!isUserEmployee}
                    disabled={isUserEmployee} />
            </div>
            <br />
            <div className={`${styles.Row} ${styles.ColumnSm}`}>
                <Input
                    placeholder='Street address'
                    value={initialState.address1}
                    onInput={setAddress1}
                    label={`Address 1:${isUserEmployee ? '' : ' *'}`}
                    required={!isUserEmployee}
                    disabled={isUserEmployee} />
                <Input
                    placeholder='Apt, unit, suite, etc.'
                    value={initialState.address2}
                    onInput={setAddress2}
                    label='Address 2:'
                    disabled={isUserEmployee} />
            </div>
            <div className={`${styles.Row} ${styles.ColumnSm}`}>
                <Input
                    placeholder='City'
                    value={initialState.city}
                    onInput={setCity}
                    label={`City:${isUserEmployee ? '' : ' *'}`}
                    maxLength="40"
                    required={!isUserEmployee}
                    disabled={isUserEmployee} />
                <div className={styles.Row}>
                    <Input
                        placeholder='State'
                        value={initialState.state}
                        onInput={setState}
                        maxLength='2'
                        pattern='[a-zA-Z]+'
                        title="Please use 2 uppercase letters."
                        label={`State:${isUserEmployee ? '' : ' *'}`}
                        required={!isUserEmployee}
                        disabled={isUserEmployee} />
                    <Input
                        placeholder='Zip code'
                        value={initialState.zipCode}
                        onInput={setZipCode}
                        pattern='[0-9]{5}'
                        maxLength='5'
                        title="Please use 5 digits."
                        label={`Zip code:${isUserEmployee ? '' : ' *'}`}
                        required={!isUserEmployee}
                        disabled={isUserEmployee} />
                </div>
            </div>
            <div className={styles.Message}>
                {errorMessage}
            </div>
            {children}
            <div className={styles.FormActions}>
                <Button large={buttonLarge} blue type='submit' isLoading={loading}>{onSubmitText}</Button>
            </div>
        </form>
    )
}
