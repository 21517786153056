import api from "http/index";

class AuthService {
    async signin(email, password) {
        var postData = new FormData();
        postData.append("email", email);
        postData.append("password", password);
        return api.post(`Auth/Basic/Signin`, postData);
    };

    async signinWithGuid(guid) {
        return api.get(`Auth/Signin/${guid}`);
    };

    async register(email, password, firstName, lastName) {
        var postData = new FormData();
        postData.append("email", email);
        postData.append("password", password);
        postData.append("firstName", firstName);
        postData.append("lastName", lastName);
        postData.append("siteOrigin", "ss");

        return api.post(`Auth/Register`, postData);
    };

    async logout() {
        return api.get(`Auth/Logout`);
    };

    async refresh() {
        return api.get(`Auth/Refresh`);
    };
}

export default new AuthService();