import React, { useLayoutEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SeniorsScheduleService from "services/SeniorsScheduleService";
import { setAuthLoading, rememberMeKey, refresh } from "store/auth";
import { setCartState } from "store/cart";
import GlobalLoading from "components/GlobalLoading";

export default function ProtectedRoute({ children, message, ...props }) {
    const dispatch = useDispatch();
    const { isAuth, isAuthLoading } = useSelector(state => state.auth);

    useLayoutEffect(() => {
        const checkAuthAndLoadState = async () => {
            dispatch(setAuthLoading(true));
            try {
                const rememberMe = localStorage.getItem(rememberMeKey);
                if (rememberMe && (rememberMe === "true") && !isAuth) {
                    const { payload } = await dispatch(refresh());
                    if (!payload.isSuccess) dispatch(setAuthLoading(false));
                    return;
                }

                if (isAuth) {
                    const { data } = await SeniorsScheduleService.loadState();
                    if (data.state) {
                        dispatch(setCartState(data.state));
                    }
                }
                dispatch(setAuthLoading(false));
            } catch {
                dispatch(setAuthLoading(false));
            }
        };
        checkAuthAndLoadState();
    }, [dispatch, isAuth]);


    if (isAuthLoading) return <GlobalLoading />;

    if (isAuth) {
        return <Route {...props}>{children}</Route>;
    } else {
        return <Redirect push to="/signin" />;
    };
};