import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTotal } from 'store/cart';

import styles from './CartTotals.module.css';

export default function CartTotals() {
    const dispatch = useDispatch();
    const { products, couponInfo, appointmentInfo } = useSelector(state => state.cart);
    const [totals, setTotals] = useState({ subtotal: 0.0, discount: 0.0, total: 0.0 });

    const formatMoney = (amount) => {
        const amountInCents = Math.round(amount * 100).toString();
        if (amountInCents === "0" || amountInCents === 0) return "0.00";
        const dollars = amountInCents.slice(0, amountInCents.length - 2);
        return `${dollars ? dollars : 0}.${amountInCents.slice(amountInCents.length - 2)}`
    };

    useLayoutEffect(() => {
        const subtotal = (products.reduce((sum, prod) => sum + (prod.totalPrice * 100), 0) / 100);
        let discount = 0.0;
        if (couponInfo && Object.keys(couponInfo).length > 0 && appointmentInfo && Object.keys(appointmentInfo).length > 0) {
            discount = couponInfo.amount + (couponInfo.percentage * subtotal / 100);
        }
        if (discount > subtotal) discount = subtotal;
        const total = Math.round((subtotal - discount) * 100) / 100;
        setTotals({ subtotal, discount, total });
        dispatch(setTotal(formatMoney(total)));
    }, [dispatch, couponInfo, products, appointmentInfo]);

    return (
        <table className={styles.CartTotalsTable}>
            <tbody>
                <tr>
                    <td className={styles.Title}>Subtotal:</td>
                    <td>${formatMoney(totals.subtotal)}*</td>
                </tr>
                <tr>
                    <td className={styles.Title}>Discount:</td>
                    <td className={parseFloat(totals.discount) !== 0 ? styles.Negative : null}>${formatMoney(totals.discount)}</td>
                </tr>
                <tr>
                    <td className={styles.Title}>Total:</td>
                    <td className={styles.Total}>${formatMoney(totals.total)}*</td>
                </tr>
                <tr>
                    <td className={styles.TaxIncluded} colSpan={2}>*Tax included</td>
                </tr>
            </tbody>
        </table>
    )
}
